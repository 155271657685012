import {apiClient, legacyApiClient, legacyApiClientFetch} from "./apiClient";
import {v4 as uuidv4} from 'uuid';

const api = () => apiClient(`/encuestas`);

export function getAllEncuestas() {
  return api().get('/');
}

export function getEncuestaSecciones(idEncuesta, idVersion) {
  return api().get(`/${idEncuesta}/versiones/${idVersion}/secciones`);
}


export function getEncuestaSeccionPreguntas(idEncuesta, idVersion, idSeccion) {
  return api().get(`/${idEncuesta}/versiones/${idVersion}/secciones/${idSeccion}/preguntas`);
}


export function postRespuesta({idEncuesta, idVersion, idConjunto, idPregunta, tipoDato, valor, opciones}) {
  return legacyApiClient('/encuestas')
    .post(`/${idEncuesta}/versiones/${idVersion}/conjunto_respuestas/${idConjunto}/preguntas/${idPregunta}`, {
      tipo_dato: tipoDato,
      valor,
      opciones
    });
}

export function crearConjunto({idPunto, idEncuesta, versionEncuesta}) {
  const uuid = uuidv4();
  return legacyApiClient('/puntos_cronograma')
    .post(`/${idPunto}/conjunto_respuestas`, {
      id_encuesta: idEncuesta,
      uuid,
      version_encuesta: versionEncuesta
    });
}

export function crearConjuntoViaticos({username, idEncuesta, versionEncuesta}) {
  const uuid = uuidv4();
  return legacyApiClient('/viaticos')
    .post(`/usuarios/${username}`, {
      id_encuesta: idEncuesta,
      uuid,
      version_encuesta: versionEncuesta
    });
}

export function getLatestConjunto(idPunto) {
  const data = legacyApiClient('/puntos_cronograma')
    .get(`/${idPunto}/conjunto_respuestas`);
  return data;
}

export function getRespuestas(idEncuesta, idConjunto) {
  return (async () => {
    const rs = await legacyApiClientFetch(`/encuestas/${idEncuesta}/conjunto_respuestas/${idConjunto}`);
    const json = await rs.json();
    return JSON.parse(JSON.stringify(json));
  })();
}


export function getViaticosMes({username, year, month}) {
  return legacyApiClient('/viaticos')
    .get(`/usuarios/${username}/year/${year}/month/${month}`);
}

