export const respuestasDataSelector = (state) => state.getIn(['respuestas']);

export const getConjuntoSelector = (state, idPunto, idEncuesta) => {
  const data = state.getIn(["respuestas", idPunto, idEncuesta, "conjunto"]);
  console.log({respuestas: "respuestas", idPunto, idEncuesta, data});
  return data;
}

export const getConjuntoViaticosSelector = (state, idPunto, idEncuesta, idConjunto, newIdentifier) => {
  if (!idConjunto && !newIdentifier) {
    return null;
  }
  const conjunto = state.getIn(["respuestas", idPunto, idEncuesta, "conjunto"]);
  if (!conjunto) {
    return null;
  }
  if (conjunto.idConjunto === idConjunto) {
    return conjunto;
  }
  if (conjunto.newIdentifier === newIdentifier) {
    return conjunto;
  }
  return null;
}

export const getRespuestasSelector = (state, idPunto, idEncuesta, idConjunto, isViaticos = false) => {
  if (isViaticos && !idConjunto) {
    return {};
  }

  const data = state.getIn(["respuestas", `${idPunto}`, `${idEncuesta}`, "respuestas"]);

  if (!isViaticos) {
    return data;
  }

  if (!data) {
    return {};
  }


  return Object.keys(data).filter(key => data[key].idConjunto === idConjunto)
    .reduce((result, key) => {
      result[key] = data[key];
      return result;
    }, {});
}
