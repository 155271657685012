import React, { useEffect } from "react";
import { Schedule } from "../Schedule";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVisitasSelector,
  isAllVisitasFetchingSelector,
} from "../../selectors/VisitasSelector";
import { getAllVisitas } from "../../actions/visitasActions";
import { FontAwesomeIconBox } from "../FontAwesomeIconBox";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/core";
import noData from "../../svg/no_data.svg";
import { InLineSkeleton } from "../InLineSkeleton";
import { userFullNameSelector } from "../../selectors/UserSelectors";

/**
 * Lista de visitas programadas
 * @returns {*}
 * @constructor
 */
export function ListaVisitas() {
  const dispatch = useDispatch();
  const visitas = useSelector((state) => getAllVisitasSelector(state));
  const isLoading = useSelector((state) => isAllVisitasFetchingSelector(state));
  const userFullName = useSelector((state) => userFullNameSelector(state));

  useEffect(() => {
    dispatch(getAllVisitas());
  }, []);

  if (!isLoading && !visitas.length) {
    return (
      <Flex justify="center" alignItems="center" height="calc(100vh - 160px)">
        <Box>
          <Image src={noData} size="300px" objectFit="cover" margin="0 auto" />
          <Text textAlign="center" fontSize="lg">
            No tienes visitas el día de hoy
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <section>
      <Flex direction="row" p={3} flexWrap="wrap" height="100%">
        <Schedule visitas={visitas} isLoading={isLoading} />
        <Flex
          direction="column"
          justify="start"
          alignItems="center"
          p="10px"
          d={["none", "none", "flex"]}
          width={[0, 0, "55%", "65%", "75%"]}
          height="calc(100vh - 80px)"
        >
          <Box mt={20} textAlign="center">
            <Heading>
              <InLineSkeleton isLoaded={userFullName != null}>
                {`Hola ${userFullName}!`}
              </InLineSkeleton>
            </Heading>
            <Image src="/insight-logo-basic.png" width="600px" mb={5} />
            <FontAwesomeIconBox icon={faArrowLeft} d="inline" mr={5} />
            <Text textAlign="center" fontSize="2xl" d="inline">
              Selecciona un punto a visitar.
            </Text>
          </Box>
        </Flex>
      </Flex>
    </section>
  );
}
