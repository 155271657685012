import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Flex, Text, Box } from '@chakra-ui/core';
import PropTypes from 'prop-types';

/**
 * Contenedor del top bar
 * @type {*}
 */
const Container = styled.div`
  width: 100%;
  height: 50px;
  background: #191e30;
  color: white;
`;

/**
 * Botón de menu
 * @type {*}
 */
const MenuButton = styled.div`
  background: transparent;
  border: none;
  cursor: pointer;
`;

/**
 * Barra superior den la pagina
 * @param {string} username Nombre de usuario
 * @param {function} onSidebarOpen hook de sidebar open
 * @returns {*}
 * @constructor
 */
function TopBar({ username, onSidebarOpen }) {
  return (
    <Container>
      <Flex justifyContent="space-between" align="center" height="100%" padding="0 10px">
        <Flex align="center">
          <MenuButton onClick={onSidebarOpen} d="inline-block">
            <FontAwesomeIcon icon={faBars} size="lg" />
          </MenuButton>
          <Box ml={4}>
            <Text fontSize="2xl">InsightBTL</Text>
          </Box>
        </Flex>
        <Avatar name={username} size="sm" />
      </Flex>
    </Container>
  );
}

TopBar.defaultProps = {
  username: '',
};

TopBar.propTypes = {
  username: PropTypes.string,
};

export { TopBar };
