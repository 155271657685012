import React, {useEffect} from "react";
import {Box, Button, Flex, IconButton} from "@chakra-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getViaticosSelector} from "../selectors/EncuestasSelector";
import {getViaticosMes} from "../actions/viaticosActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoice, faGasPump} from "@fortawesome/free-solid-svg-icons";
import {VIATICOS_COMBUSTIBLE_ID} from "../config";
import {Link} from "react-router-dom";

export default function ViaticosList({username}) {
  const dispatch = useDispatch();
  const viaticos = useSelector(state => getViaticosSelector(state));

  useEffect(() => {
    const currentDate = new Date();
    dispatch(getViaticosMes({username, year: currentDate.getFullYear(), month: currentDate.getMonth() + 1}))
  }, []);

  if (!viaticos.length) {
    return null;
  }

  const items = viaticos.map(x => {
    let icon = faFileInvoice;
    if (x.id_encuesta == VIATICOS_COMBUSTIBLE_ID) {
      icon = faGasPump;
    }
    return <Box padding={3} mb={2} shadow="md" borderWidth="1px" position="relative" width="100%" key={x.conjunto_id}>
      <Flex direction="row" justifyContent="space-between">
        <Box mr={5}>
          <FontAwesomeIcon size="3x" icon={icon}/>
        </Box>
        <Box>
          Fecha: {x.date}
          <br/>
          Tipo: {x.type || 'Factura'}
        </Box>
        <Box ml={5}>
          <IconButton
            as={Link} to={`/viaticos/${username}/encuesta/${x.id_encuesta}/conjunto/${x.conjunto_id}`}
            variant="ghost"
            variantColor="teal"
            aria-label="Call Sage"
            fontSize="20px"
            icon="arrow-forward"
          />
        </Box>
      </Flex>
    </Box>
  });


  return (
    <Box p={2} width="100%">
      {items}
    </Box>
  );
}
