import React, {useEffect} from "react";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from "@chakra-ui/core";

export default function PreguntaNumber({value, opciones, onChange, init, min, max, step, precision}) {
  const [currentValue, setCurrentValue] = React.useState(value || null);

  useEffect(() => {
    init(currentValue, "number");
  }, []);

  return (
    <NumberInput defaultValue={currentValue} step={step || 1} precision={precision || 0} min={min || 0}
                 max={max || Infinity} onChange={(val) => {
      setCurrentValue(val);
      onChange(val);
    }}>
      <NumberInputField/>
      <NumberInputStepper>
        <NumberIncrementStepper/>
        <NumberDecrementStepper/>
      </NumberInputStepper>
    </NumberInput>
  );
}
