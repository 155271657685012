/**
 * Identificador de acción de solicitud de datos de usuario
 * @type {string}
 */
export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';

/**
 * Identificador de acción de fallo al obtener datos del usuario
 * @type {string}
 */
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

/**
 * Identificador de acción de éxito al obtener datos del usuario
 * @type {string}
 */
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

/**
 * Crear acción para solicitar datos del usuario
 * @returns {{type: string}}
 */
export const getUserData = () => ({
  type: GET_USER_DATA_REQUEST,
});

/**
 * Crear acción de fallo al obtener información del usuario
 * @param {string} message
 * @returns {{type: string, message: *}}
 */
export const getUserDataFailure = (message) => ({
  type: GET_USER_DATA_FAILURE,
  message,
});

/**
 * Crear acción de éxito al obtener datos del usuario
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const getUserDataSuccess = (data) => ({
  type: GET_USER_DATA_SUCCESS,
  data,
});
