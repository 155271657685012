import React from "react";
import {Box, Flex} from "@chakra-ui/core";
import PreguntasContainer from "./Pregunta/PreguntasContainer";

export default function SectionSinglePage({title, description, preguntas, conjunto, encuesta, respuestas}) {

  return (
    <Box p={2}>
      <Flex align="center">
        <span style={{flexGrow: 1}}>{title}</span>
      </Flex>
      <Box mb={2}>
        {description}
      </Box>
      <PreguntasContainer preguntas={preguntas} encuesta={encuesta} conjunto={conjunto} respuestas={respuestas}/>
    </Box>
  );
}
