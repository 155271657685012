import React from "react";
import PreguntaBase from "./PreguntaBase";
import PreguntaComment from "./PreguntaComment";
import PreguntaRadioButton from "./PreguntaRadioButton";
import PreguntaNumber from "./PreguntaNumber";
import PreguntaDate from "./PreguntaDate";
import {VIATICOS_ENCUESTA_IDS} from "../../../config";

export default function PreguntasContainer({encuesta, conjunto, preguntas, isNested = false, respuestas = {}}) {

  if (!preguntas) {
    return null;
  }

  const items = preguntas.filter(p => isNested || !p.idPadre).map(p => {
    let input = null;
    let res = respuestas[p.idPregunta] || {valor: '', opciones: []};
    switch (p.tipo) {
      case "comment":
        input = <PreguntaComment value={res.valor}/>
        break;
      case "radiogroup":
        let optId = 0;
        if (res.opciones.length) {
          optId = res.opciones[0].id_opcion;
        }
        input = <PreguntaRadioButton opciones={p.opciones} value={optId}/>
        break;
      case "number":
        let max = 100;
        let precision = 0;
        let steps = 1;
        if (VIATICOS_ENCUESTA_IDS.includes(encuesta.idEncuesta)) {
          max = Infinity;
          precision = 2;
          steps = 0.01
        }
        input = <PreguntaNumber value={res.valor} min={0} max={max} step={steps} precision={precision}/>
        break;
      case "date":
        input = <PreguntaDate value={res.valor}/>;
    }

    const subPreguntas = preguntas.filter(sp => sp.idPadre === p.idPregunta);

    return (
      <PreguntaBase key={p.idPregunta} pregunta={p} encuesta={encuesta} conjunto={conjunto} subPreguntas={subPreguntas} respuestas={respuestas}>
        {input}
      </PreguntaBase>
    );
  });

  return (
    <>
      {items}
    </>
  );
}
