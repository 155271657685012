import {fromJS} from "immutable";
import * as actions from '../actions/oneDriveActions'


const initialState = fromJS({
  data: [],
  error: null,
  isLoading: false
});

export default function oneDriveReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ALL_FILES_IN_DIR_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        error: null
      });
    case actions.GET_ALL_FILES_IN_DIR_SUCCESS:
      return state.merge({
        data: action.data,
        error: null,
        isLoading: false,
      });

    case actions.GET_ALL_FILES_IN_DIR_FAILURE:
      return state.mergeDeep({
        error: action.error,
        isLoading: false
      });

    default:
      return state;
  }
}
