import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/core';
import { useHistory, Redirect } from 'react-router-dom';
import { TopBar } from './TopBar';
import { SideBar } from './SideBar';
import { userClienteSelector, userFullNameSelector, userRolSelector } from '../../selectors/UserSelectors';
import { getUserData } from '../../actions/userActions';
import { isAuthenticatedSelector } from '../../selectors/AuthSelectors';

/**
 * Grupo de componentes destinados a la navegación de la pagina
 * @returns {*}
 * @constructor
 */
export function Navigation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const username = useSelector((state) => userFullNameSelector(state));
  const rol = useSelector((state) => userRolSelector(state));
  const project = useSelector((state) => userClienteSelector(state));
  const isAuthenticated = useSelector((state) => isAuthenticatedSelector(state));

  useEffect(() => {
    if (!username || !rol || !project) {
      dispatch(getUserData());
    }
  }, []);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <nav>
      <TopBar username={username} onSidebarOpen={onOpen} />
      <SideBar username={username} userRole={rol} userProject={project} isOpen={isOpen} onClose={onClose} />
    </nav>
  );
}
