import React from 'react';
import {
  Avatar,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/core';
import { faHome, faKey, faSignOutAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIconBox } from '../FontAwesomeIconBox';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/authActions';

/**
 * Sidebar de la pagina
 * @param {string} username Nombre del usuario logueado
 * @param {string} userRole Rol del usuario
 * @param {string} userProject Proyecto al que pertenece el usuario
 * @param {boolean} isOpen true si el sidebar esta abierto, false si esta oculto
 * @param onClose hook de onclose
 * @returns {*}
 * @constructor
 */
export function SideBar({ username, userRole, userProject, isOpen, onClose }) {
  const dispatch = useDispatch();

  // Iniciar acción de cierre de sesión
  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen} placement="left">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color="white" />
        <DrawerHeader background="#191e30">
          <Avatar name={username} mb={2} />
          <Text color="white" fontSize="lg">
            {username}
          </Text>
          <Text color="white" fontSize="sm">
            {userRole}
          </Text>
          <Text color="white" fontSize="xs">
            {userProject}
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <Button variant="link" width="100%" d="flex" justifyContent="start" p={2} size="lg" as={Link} to="/">
            <FontAwesomeIconBox icon={faHome} mr={4} />
            Inicio
          </Button>
          <Button variant="link" width="100%" d="flex" justifyContent="start" p={2} size="lg" as={Link} to="/viaticos">
            <FontAwesomeIconBox icon={faFileInvoiceDollar} mr={4} />
            Viáticos
          </Button>
          <Button
            variant="link"
            width="100%"
            d="flex"
            justifyContent="start"
            p={2}
            size="lg"
            mt={2}
            as={Link}
            to="/password"
          >
            <FontAwesomeIconBox icon={faKey} mr={4} />
            Cambiar contraseña
          </Button>
          <Button variant="link" width="100%" d="flex" justifyContent="start" p={2} size="lg" mt={2} onClick={onLogout}>
            <FontAwesomeIconBox icon={faSignOutAlt} mr={4} />
            Cerrar sesión
          </Button>
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
}
