/**
 * Obtener todas las visitas del usuario
 * @param {object} state
 * @returns {array<any>}
 */
export const getAllVisitasSelector = (state) => state.getIn(['visitas', 'collection']);

/**
 * Verificar si las visitas aun están cargando
 * @param {object} state
 * @returns {boolean}
 */
export const isAllVisitasFetchingSelector = (state) => state.getIn(['visitas', 'isFetching']);

/**
 * Obtener mensaje de error al cargar visitas
 * @param {object} state
 * @returns {string}
 */
export const allVisitasErrorSelector = (state) => state.getIn(['visitas', 'errorMessage']);

/**
 * Obtener el estado de carga de cada visita
 * @param {object} state
 * @returns {any}
 */
export const allLoadingVisitasSelector = (state) => state.getIn(['visitas', 'fetchingIds']);

/**
 * Verificar si los datos de una visita aun están cargando
 * @param {object} state component state
 * @param {number} idVisita Id de visita a verificar
 * @returns {boolean}
 */
export const isVisitaLoading = (state, idVisita) => {
  return state.getIn(['visitas', 'fetchingIds', `${idVisita}`]) === true;
};

/**
 * Obtener datos de visita
 * @param {object} state
 * @param {number} idVisita Id de visita solicitada
 * @returns {null|array}
 */
export const visitaByIdSelector = (state, idVisita) => {
  // Es importante esta conversión ya que el valor puede ser numérico dependiendo si proviene o no de la url.
  const id = Number(idVisita);

  const index = state.getIn(['visitas', 'collection']).findIndex((x) => {
    return x.id === id;
  });

  if (index === -1) {
    return null;
  }

  return state.getIn(['visitas', 'collection', index]);
};

/**
 * Obtener hora de checkin de una visita.
 * @param {object} state
 * @param {number} idVisita
 * @returns {string|undefined}
 */
export const visitaCheckInSelector = (state, idVisita) => {
  const visita = visitaByIdSelector(state, idVisita);
  if (!visita) {
    return undefined;
  }
  return visita.checkIn;
};

/**
 * Obtener hora de checkout de una visita
 * @param {object} state
 * @param {number} idVisita
 * @returns {string|undefined}
 */
export const visitaCheckOutSelector = (state, idVisita) => {
  const visita = visitaByIdSelector(state, idVisita);
  if (!visita) {
    return undefined;
  }
  return visita.checkOut;
};

/**
 * Obtener hora en la que se inicio el horario de almuerzo
 * @param {object} state
 * @param {number} idVisita
 * @returns {string|undefined}
 */
export const visitaStartLunchSelector = (state, idVisita) => {
  const visita = visitaByIdSelector(state, idVisita);
  if (!visita) {
    return undefined;
  }
  return visita.inicioAlmuerzo;
};

/**
 * Obtener hora en la que se finalizo el horario de almuerzo
 * @param {object} state
 * @param {number} idVisita
 * @returns {string|undefined}
 */
export const visitaFinishLunchSelector = (state, idVisita) => {
  const visita = visitaByIdSelector(state, idVisita);
  if (!visita) {
    return undefined;
  }
  return visita.finAlmuerzo;
};

/**
 * Obtener datos del pdv de una visita
 * @param {object} state
 * @param {number} idVisita
 * @returns {undefined|object}
 */
export const visitaPdvSelector = (state, idVisita) => {
  const visita = visitaByIdSelector(state, idVisita);
  if (!visita) {
    return undefined;
  }
  return visita.pdv;
};
