import React, {useEffect, useState} from "react";
import {Input} from "@chakra-ui/core";

export default function PreguntaComment({value, onChange, init}) {
  const [timeoutId, setTimeoutId] = useState(0);
  const [val, setVal] = useState(value || '');

  useEffect(() => {
    init(value, "comment");
  }, []);

  useEffect(() => {
    if (timeoutId === 0) {
      return;
    }

    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const handleChange = (newVal) => {
    setVal(newVal || '');
    if (timeoutId !== 0) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      onChange(newVal);
    }, 1000);

    setTimeoutId(id);
  };

  return (
    <Input size="md" variant="outline" onChange={e => handleChange(e.target.value)} value={val}/>
  );
}
