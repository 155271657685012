import {fromJS} from "immutable";
import * as actions from "../actions/viaticosActions";

const initState = fromJS({
  isFetching: false,
  errorMessage: null,
  collection: []
});

export default function viaticosReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_VIATICOS_MES_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null,
        collection: []
      });
    case actions.GET_VIATICOS_MES_SUCCESS:
      return state.merge({
        isFetching: false,
        errorMessage: null,
        collection: action.data
      });

    case actions.GET_VIATICOS_MES_FAILURE:
      return state.merge({
        isFetching: false,
        errorMessage: action.error
      });
    default:
      return state;
  }
}
