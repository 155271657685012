import { combineReducers } from 'redux-immutable';
import { authReducer } from './authReducer';
import { visitasReducer } from './visitasReducer';
import { userReducer } from './userReducer';
import { LOGOUT } from '../actions/authActions';
import encuestasReducer from "./encuestasReducer";
import respuestasReducer from "./respuestasReducer";
import viaticosReducer from "./viaticosReducer";
import prospeccioesnReducer from './prospeccionesReducer';
import oneDriveReducer from './oneDriveReducer'

/**
 * Combinar todos los reducers para el store
 */
const appReducer = combineReducers({
  auth: authReducer,
  visitas: visitasReducer,
  usuario: userReducer,
  encuestas: encuestasReducer,
  respuestas: respuestasReducer,
  viaticos: viaticosReducer,
  prospecciones: prospeccioesnReducer,
  oneDrive: oneDriveReducer
});

export default function rootReducer(state, action) {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}
