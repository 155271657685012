import { apiClient } from './apiClient';

const api = () => apiClient('/auth');

/**
 * Solicitar de autenticación
 * @param {object} credentials
 * @returns {Promise<AxiosResponse<any>>}
 */
export function login(credentials) {
  return api().post(`/login`, credentials);
}

/**
 * Solicitar identidad del cliente
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getIdentity() {
  return api().get('/identity');
}
