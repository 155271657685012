import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../service/visitasService';
import {
  ALL_VISITAS_REQUEST,
  DO_CHECK_IN_REQUEST,
  DO_CHECK_OUT_REQUEST,
  doCheckInFailure,
  doCheckInSuccess,
  doCheckOutFailure,
  doCheckOutSuccess,
  FINISH_LUNCH_REQUEST,
  finishLunchFailure,
  finishLunchSuccess,
  getAllVisitasFailure,
  getAllVisitasSuccess,
  getVisitaByIdFailure,
  getVisitaByIdSuccess,
  START_LUNCH_REQUEST,
  startLunchFailure,
  startLunchSuccess,
  VISITA_BY_ID_REQUEST,
} from '../actions/visitasActions';

/**
 * Obtener todas las visitas del usuario
 * @returns {Generator<<"PUT", PutEffectDescriptor<{type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* visitasWorker() {
  try {
    const response = yield call(api.getVisitas, null);
    yield put(getAllVisitasSuccess(response.data));
  } catch (e) {
    yield put(getAllVisitasFailure(e.message || 'Error al obtener visitas del dia'));
  }
}

/**
 * Obtener datos de una visita especifica
 * @param {object} action
 * @returns {Generator<<"PUT", PutEffectDescriptor<{idVisita: *, type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* visitaByIdWorker(action) {
  try {
    const response = yield call(api.getVisitaById, action.idVisita);
    yield put(getVisitaByIdSuccess(response.data));
  } catch (e) {
    yield put(getVisitaByIdFailure(action.idVisita, e.message || 'Error al obtener visitas del dia'));
  }
}

/**
 * Solicitar checkout del usuario
 * @param {object} action
 * @returns {Generator<<"PUT", PutEffectDescriptor<{idVisita: *, type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* checkOutWorker(action) {
  try {
    const response = yield call(api.doCheckOut, action.idVisita);
    yield put(doCheckOutSuccess(response.data));
  } catch (e) {
    yield put(doCheckOutFailure(action.idVisita, e.message || 'Error al obtener visitas del dia'));
  }
}

/**
 * Solicitar checkin del usuario
 * @param {object} action
 * @returns {Generator<<"PUT", PutEffectDescriptor<{idVisita: *, type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* checkInWorker(action) {
  try {
    const response = yield call(api.doCheckIn, action.idVisita);
    yield put(doCheckInSuccess(response.data));
  } catch (e) {
    yield put(doCheckInFailure(action.idVisita, e.message || 'Error al obtener visitas del dia'));
  }
}

/**
 * Solicitar iniciar horario de almuerzo
 * @param {object} action
 * @returns {Generator<<"PUT", PutEffectDescriptor<{idVisita: *, type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* startLunchWorker(action) {
  try {
    const response = yield call(api.startLunch, action.idVisita);
    yield put(startLunchSuccess(response.data));
  } catch (e) {
    yield put(startLunchFailure(action.idVisita, e.message || 'Error al obtener visitas del dia'));
  }
}

/**
 * Solicitar finalizar horario de almuerzo
 * @param {object} action
 * @returns {
 * Generator<<"PUT", PutEffectDescriptor<{idVisita: *, type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* finishLunchWorker(action) {
  try {
    const response = yield call(api.finishLunch, action.idVisita);
    yield put(finishLunchSuccess(response.data));
  } catch (e) {
    yield put(finishLunchFailure(action.idVisita, e.message || 'Error al obtener visitas del dia'));
  }
}

/**
 * Observar todas las acciones emitidas que corresponden a la visita
 * @returns {Generator<<"FORK", ForkEffectDescriptor<any>>, void, *>}
 */
export function* visitasWatcherSaga() {
  yield takeLatest(ALL_VISITAS_REQUEST, visitasWorker);
  yield takeLatest(VISITA_BY_ID_REQUEST, visitaByIdWorker);
  yield takeLatest(DO_CHECK_IN_REQUEST, checkInWorker);
  yield takeLatest(DO_CHECK_OUT_REQUEST, checkOutWorker);
  yield takeLatest(START_LUNCH_REQUEST, startLunchWorker);
  yield takeLatest(FINISH_LUNCH_REQUEST, finishLunchWorker);
}
