import {fromJS} from "immutable";
import * as actions from '../actions/encuestasActions'

const initState = fromJS({
  isFetching: false,
  errorMessage: null,
  collection: []
});


const updateSecciones = (state, idEncuesta, secciones) => {
  const index = state.get("collection").findIndex((x) => x.idEncuesta === Number(idEncuesta));
  return state.setIn(["collection", index, 'secciones'], secciones);
};


const updatePreguntas = (state, idEncuesta, idSeccion, preguntas) => {
  const indexEncuesta = state.get("collection").findIndex((x) => x.idEncuesta === Number(idEncuesta));
  const indexSeccion = state.getIn(["collection", indexEncuesta, "secciones"]).findIndex(x => x.idSeccion === Number(idSeccion));
  return state.setIn(["collection", indexEncuesta, "secciones", indexSeccion, "preguntas"], preguntas);
};

export default function encuestasReducer(state = initState, action) {
  switch (action.type) {
    case actions.ALL_ENCUESTAS_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null
      });
    case actions.ALL_ENCUESTAS_FAILURE:
      return state.merge({
        isFetching: false,
        errorMessage: action.error
      });
    case actions.ALL_ENCUESTAS_SUCCESS:
      return state.merge({
        isFetching: false,
        collection: action.data
      });

    case actions.GET_ENCUESTA_SECCIONES_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null
      });

    case actions.GET_ENCUESTA_SECCIONES_FAILURE:
      return state.merge({
        isFetching: false,
        errorMessage: action.error
      });

    case actions.GET_ENCUESTA_SECCIONES_SUCCESS:
      return updateSecciones(state, action.idEncuesta, action.data).merge({
        isFetching: false,
        errorMessage: null
      });

    case actions.GET_SECCION_PREGUNTAS_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null
      });

    case actions.GET_SECCION_PREGUNTAS_FAILURE:
      return state.merge({
        isFetching: false,
        errorMessage: action.error
      });

    case actions.GET_SECCION_PREGUNTAS_SUCCESS:
      const newState = updatePreguntas(state, action.idEncuesta, action.idSeccion, action.data).merge({
        isFetching: false,
        errorMessage: null
      });
      return newState;

    default:
      return state;
  }
}
