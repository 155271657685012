import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_VIATICOS_MES_REQUEST, getViaticosMesFailure, getViaticosMesSuccess} from "../actions/viaticosActions";
import * as api from "../service/encuestasService";

function* getViaticos(action) {
  try {
    const response = yield call(api.getViaticosMes, action);
    yield put(getViaticosMesSuccess({...action, data: response.data}));
  } catch (e) {
    yield put(getViaticosMesFailure({...action, error: e.message || 'Error al obtener secciones'}));
  }
}

export default function* viaticosSaga() {
  yield takeLatest(GET_VIATICOS_MES_REQUEST, getViaticos);
}
