import { createSelector } from 'reselect';

/**
 * Obtener estado completo del usuario
 * @param {object} state
 * @returns {string}
 */
export const userDataSelector = (state) => state.getIn(['usuario']);

/**
 * Obtener username del usuario
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const usernameSelector = createSelector(userDataSelector, (user) => user.get('username'));

/**
 * Obtener nombre del usuario
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const userFullNameSelector = createSelector(userDataSelector, (user) => user.get('nombre'));

/**
 * Obtener estado del usuario. activo||deshabilitado
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const userStatusSelector = createSelector(userDataSelector, (user) => user.get('status'));

/**
 * Obtener nombre del cliente asociado al usuario
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const userClienteSelector = createSelector(userDataSelector, (user) => user.get('cliente'));

/**
 * Obtener rol del usuario
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const userRolSelector = createSelector(userDataSelector, (user) => user.get('rol'));

/**
 * Obtener estado de carga de solicitudes del usuario
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const isUserFetchingSelector = createSelector(userDataSelector, (user) => user.get('isFetching'));

/**
 * Obtener mesaje de error
 * @type {OutputSelector<unknown, *, (res: any) => *>}
 */
export const userErrorMessageSelector = createSelector(userDataSelector, (user) => user.get('errorMessage'));
