import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../service/authService';
import { LOGIN_REQUEST, loginFailure, loginSuccess, LOGOUT } from '../actions/authActions';

/**
 * Autenticar usuario
 * @param {object} credentials
 * @returns {Generator<<"PUT", PutEffectDescriptor<{type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
function* loginWorker({ credentials }) {
  try {
    const response = yield call(api.login, credentials);
    localStorage.setItem('token', response.data.token);
    yield put(loginSuccess());
  } catch (e) {
    localStorage.clear();
    yield put(loginFailure(e.message || 'Error al autenticar usuario'));
  }
}

/**
 * Cerrar sesión de usuario
 * @returns {Generator<never, void, *>}
 */
function* logoutWorker() {
  localStorage.clear();
}

/**
 * Observar todas las acciones emitidas de autenticación
 * @returns {Generator<<"FORK", ForkEffectDescriptor<any>>, void, *>}
 */
export function* authWatcherSaga() {
  yield takeLatest(LOGIN_REQUEST, loginWorker);
  yield takeEvery(LOGOUT, logoutWorker);
}
