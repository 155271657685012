export const GET_ALL_FILES_IN_DIR_REQUEST = 'GET_ALL_FILES_IN_DIR';
export const GET_ALL_FILES_IN_DIR_SUCCESS = 'GET_ALL_FILES_IN_DIR_SUCCESS';
export const GET_ALL_FILES_IN_DIR_FAILURE = 'GET_ALL_FILES_IN_DIR_FAILURE';


export const getAllFilesInDir = (path = '/', accessToken = '') => ({
  type: GET_ALL_FILES_IN_DIR_REQUEST,
  accessToken,
  path
});

export const getAllFilesInDirSuccess = (data) => ({
  type: GET_ALL_FILES_IN_DIR_SUCCESS,
  data
});

export const getAllFilesInDirFailure = (error) => ({
  type: GET_ALL_FILES_IN_DIR_FAILURE,
  error
});
