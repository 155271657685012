import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftElement } from '@chakra-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

/**
 * Cambiar contraseña
 * @returns {*}
 * @constructor
 */
export function ChangePassword() {
  const [passwords, setPasswords] = useState({
    passwordA: null,
    passwordB: null,
  });

  /**
   * Acción a ejecutar al cambiar contraseña
   * @param e
   */
  const onPasswordsChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const invalidPassword = passwords.passwordA !== passwords.passwordB;

  return (
    <section>
      <Heading textAlign="center" mt={2}>
        Cambiar contraseña
      </Heading>
      <Box p={4}>
        <FormControl mt={2} isInvalid={invalidPassword} isDisabled={false}>
          <FormLabel htmlFor="passwordA">Contraseña</FormLabel>
          <InputGroup>
            <InputLeftElement>
              <FontAwesomeIcon icon={faKey} />
            </InputLeftElement>
            <Input type="password" id="passwordA" name="passwordA" onChange={onPasswordsChange} />
          </InputGroup>
        </FormControl>

        <FormControl mt={2} isInvalid={invalidPassword} isDisabled={false}>
          <FormLabel htmlFor="passwordB">Confirmar contraseña</FormLabel>
          <InputGroup>
            <InputLeftElement>
              <FontAwesomeIcon icon={faKey} />
            </InputLeftElement>
            <Input type="password" id="passwordB" name="passwordB" onChange={onPasswordsChange} />
          </InputGroup>
        </FormControl>
        <Box textAlign="left">
          <Button
            mt={5}
            variantColor="teal"
            rightIcon="arrow-forward"
            isLoading={false}
            isDisabled={invalidPassword || !passwords.passwordA || !passwords.passwordB}
          >
            Iniciar sesión
          </Button>
        </Box>
      </Box>
    </section>
  );
}
