import { fromJS } from 'immutable';
import * as actions from '../actions/userActions';

// Estado inicial inmutable
const initialState = fromJS({
  isFetching: false,
  errorMessage: null,
  username: null,
  nombre: null,
  status: null,
  cliente: null,
  rol: null,
});

/**
 * Reducer encargado de los estados de autenticación
 * @param {object} state estado actual
 * @param {object} action acción emitida por el action creator
 * @returns {object} nuevo estado
 */
export function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_USER_DATA_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null,
      });

    case actions.GET_USER_DATA_FAILURE:
      return state.merge({
        isFetching: false,
        errorMessage: action.message,
      });

    case actions.GET_USER_DATA_SUCCESS:
      return state.merge({
        isFetching: false,
        errorMessage: null,
        username: action.data.id,
        nombre: action.data.nombre,
        cliente: action.data.cliente,
        rol: action.data.rol,
      });

    default:
      return state;
  }
}
