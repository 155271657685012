import axios from "axios";
import {AZURE_APP_ID, AZURE_TENANT_ID, ONE_DRIVE_PASSWORD, ONE_DRIVE_SCOPES, ONE_DRIVE_USERNAME} from "../config";

const baseEndpoint = 'https://graph.microsoft.com/v1.0/me/drive/special/approot'

export async function auth() {
  const auth_endpoint = `https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/v2.0/token`;

  const params = new URLSearchParams()
  params.append('client_id', AZURE_APP_ID)
  params.append('scope', ONE_DRIVE_SCOPES.join(' '))
  params.append('username', ONE_DRIVE_USERNAME)
  params.append('password', ONE_DRIVE_PASSWORD)
  params.append('grant_type', 'password')

  return await axios.post(auth_endpoint, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    },
  });
}

export async function list({path = '/', accessToken = ''}) {
  let realPath = path === '/' ? '' : path;
  if (realPath.length > 0 && !realPath.startsWith(':')) {
    realPath = `:${realPath}`;
  }

  if (realPath.length > 0 && !realPath.endsWith(':')) {
    realPath = `${realPath}:`;
  }
  const endpoint = `${baseEndpoint}${realPath}/children`

  return await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export async function listWithAuth(path = '/') {
  const authResponse = auth();
  console.log(authResponse);
  return list(path, authResponse.data['access_token']);
}
