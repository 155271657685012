import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';

/**
 * Definir store del app
 * @param [enhancer] middlewares
 * @returns {Store<unknown, Action>}
 */
export function appStore(middlewares = []) {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
}
