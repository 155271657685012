import React, {useState} from "react";
import {Box, Flex, Heading, Icon, Text} from "@chakra-ui/core";
import {useDispatch} from "react-redux";
import {postRespuesta} from "../../../actions/respuestasActions";
import ModalDetalles from "./ModalDetalles";

const shouldShowDetails = (val, tipo) => {
  if (tipo === "radiogroup") {
    return Number(val.id_opcion) === 28;
  }

  if (tipo === "number" && val !== null) {
    return Number(val) < 100;
  }

  return false;
}

const getTipoDato = (tipo) => {
  switch (tipo) {
    case "radiogroup":
      return "SINGLE";
    default:
      return "NVARCHAR";
  }
};


export default function PreguntaBase({encuesta, conjunto, pregunta, subPreguntas, children, respuestas}) {
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();

  const requiredText = <Text fontSize="10px" color="tomato" fontWeight="semibold">Requerido</Text>;
  const helpIcon = <Icon name="question"/>

  const init = (val, tipo) => {
    setShowDetails(shouldShowDetails(val, tipo));
  };

  const onChange = val => {
    setShowDetails(shouldShowDetails(val, pregunta.tipo));
    const tipoDato = getTipoDato(pregunta.tipo);
    dispatch(postRespuesta({
      idPunto: conjunto.punto_cronograma_id || conjunto.id_usuario,
      idEncuesta: encuesta.idEncuesta,
      idVersion: encuesta.currentVersion,
      idPregunta: pregunta.idPregunta,
      idConjunto: conjunto.idConjunto,
      tipoDato: tipoDato,
      valor: tipoDato === 'NVARCHAR' ? val : null,
      opciones: ['SINGLE', 'MULTI'].includes(tipoDato) ? [val] : []
    }))
  }

  const childrenWithProps = React.Children.map(children, child => {
    const props = {...children.props, onChange, init};
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <Box mb={1} p={2} borderWidth="1px">
      {pregunta.requerida ? requiredText : null}
      <Flex align="center">
        <Heading as="h4" size="sm" style={{flexGrow: 1}}>
          {pregunta.titulo}
        </Heading>
        {pregunta.helpText ? helpIcon : null}
      </Flex>
      <Box mt={2}>
        {childrenWithProps}
      </Box>
      {!subPreguntas.length ? null : <Box mt={2} textAlign="center">
        <ModalDetalles shouldShow={showDetails} subPreguntas={subPreguntas} encuesta={encuesta} conjunto={conjunto} respuestas={respuestas}/>
      </Box>}
    </Box>
  );
}
