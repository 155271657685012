/**
 * Identificador de acción login
 * @type {string}
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

/**
 * Identificador de acción de login exitoso
 * @type {string}
 */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

/**
 * Identificador de acción de login fallido
 * @type {string}
 */
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

/**
 * Identificador d acción de logout
 * @type {string}
 */
export const LOGOUT = 'LOGOUT';

/**
 * Crear de acción para autenticación de usuario
 * @param {string} username
 * @param {string} password
 * @returns {{credentials: {password: *, username: *}, type: string}}
 */
export const login = ({ username, password }) => ({
  type: LOGIN_REQUEST,
  credentials: { username, password },
});

/**
 * Crear acción de fallo de login
 * @param {string} message
 * @returns {{type: string, message: *}}
 */
export const loginFailure = (message) => ({
  type: LOGIN_FAILURE,
  message,
});

/**
 * Crear acción de login exitoso
 * @returns {{type: string}}
 */
export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

/**
 * Crear acción para cerrar sesión
 * @returns {{type: string}}
 */
export const logout = () => ({
  type: LOGOUT,
});
