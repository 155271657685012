import React from 'react';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import { insightBtlTheme } from './theme';
import { Routes } from './Routes';

/**
 * Componente padre del sitio
 * @returns {*}
 * @constructor
 */
function App() {
  return (
    <ThemeProvider theme={insightBtlTheme}>
      <CSSReset />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
