import React from "react";
import {Box, Button, Heading, Text} from "@chakra-ui/core";
import {Link} from "react-router-dom";

export default function EncuestaCard({idVisita, idEncuesta, title, description, ...rest}) {
  return (
    <Box padding={3} shadow="md" borderWidth="1px" m="6px" position="relative" {...rest}>
      <Heading as="h3" size="lg" isTruncated>{title}</Heading>
      <Box>
        <Text mt={2} ml={2} d="inline-block">
          {description}
        </Text>
      </Box>
      <Box textAlign="left">
        <Button variantColor="teal" variant="solid" as={Link} to={`/visita/${idVisita}/encuestas/${idEncuesta}`}>
          Llenar
        </Button>
      </Box>
    </Box>
  );
}
