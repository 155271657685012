export const ALL_ENCUESTAS_REQUEST = 'GET_ALL_ENCUESTAS_REQUEST';
export const ALL_ENCUESTAS_SUCCESS = 'GET_ALL_ENCUESTAS_SUCCESS';
export const ALL_ENCUESTAS_FAILURE = 'GET_ALL_ENCUESTAS_FAILURE';

export const getAllEncuestas = () => ({
  type: ALL_ENCUESTAS_REQUEST
});
export const getAllEncuestasFailure = (error) => ({
  type: ALL_ENCUESTAS_FAILURE,
  error
})
export const getAllEncuestasSuccess = (data) => ({
  type: ALL_ENCUESTAS_SUCCESS,
  data
})


export const GET_ENCUESTA_SECCIONES_REQUEST = 'GET_ENCUESTA_SECCIONES_REQUEST';
export const GET_ENCUESTA_SECCIONES_FAILURE = 'GET_ENCUESTA_SECCIONES_FAILURE';
export const GET_ENCUESTA_SECCIONES_SUCCESS = 'GET_ENCUESTA_SECCIONES_SUCCESS';

export const getEncuestaSecciones = ({idEncuesta, idVersion}) => ({
  type: GET_ENCUESTA_SECCIONES_REQUEST,
  idEncuesta,
  idVersion
});
export const getEncuestaSeccionesFailure = (error) => ({
  type: GET_ENCUESTA_SECCIONES_FAILURE,
  error
})
export const getEncuestaSeccionesSuccess = (idEncuesta, data) => ({
  type: GET_ENCUESTA_SECCIONES_SUCCESS,
  idEncuesta,
  data
})


export const GET_SECCION_PREGUNTAS_REQUEST = 'GET_SECCION_PREGUNTAS_REQUEST';
export const GET_SECCION_PREGUNTAS_FAILURE = 'GET_SECCION_PREGUNTAS_FAILURE';
export const GET_SECCION_PREGUNTAS_SUCCESS = 'GET_SECCION_PREGUNTAS_SUCCESS';

export const getSeccionPreguntas = ({idEncuesta, idVersion, idSeccion,cb}) => ({
  type: GET_SECCION_PREGUNTAS_REQUEST,
  idEncuesta,
  idVersion,
  idSeccion,
  cb,
});

export const getSeccionPreguntasFailure = (error) => ({
  type: GET_SECCION_PREGUNTAS_FAILURE,
  error
})

export const getSeccionPreguntasSuccess = ({idEncuesta, idSeccion, data}) => ({
  type: GET_SECCION_PREGUNTAS_SUCCESS,
  idEncuesta,
  idSeccion,
  data
})

