import {legacyApiClient} from "./apiClient";

const api = () => legacyApiClient('/prospecciones');


export function getAllDepartamentos() {
  return api().get('/departamentos');
}

export function getDepartamentoMunicipios(idDepartamento) {
  return api().get(`/departamentos/${idDepartamento}/municipios`);
}

export function addProspeccion(data) {
  return api().post('/puntos', data);
}
