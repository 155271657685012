import { format } from 'date-fns';
import { apiClient, getUsername } from './apiClient';

const api = () => apiClient(`/usuarios/${getUsername()}/visitas`);

/**
 * Solicitar todas las visitas programadas para una fecha especifica
 * @param {string} date fecha de visitas
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getVisitas(date = null) {
  const dateParam = date || format(new Date(), 'yyyy-MM-dd');
  return api().get(`?date=${dateParam}`);
}

/**
 * Solicitar los datos de una visita especifica
 * @param {number} id Identificador de la visita
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getVisitaById(id) {
  return api().get(`/${id}`);
}

/**
 * Solicitar checkout
 * @param {number} idVisita
 * @returns {Promise<AxiosResponse<any>>}
 */
export function doCheckOut(idVisita) {
  return api().put(`/${idVisita}`, {
    checkOut: format(new Date(), 'HH:mm:ss'),
  });
}

/**
 * Solicitar checkin
 * @param {number} idVisita
 * @returns {Promise<AxiosResponse<any>>}
 */
export function doCheckIn(idVisita) {
  return api().put(`/${idVisita}`, {
    checkIn: format(new Date(), 'HH:mm:ss'),
  });
}

/**
 * Solicitar inicio de horario de almuerzo
 * @param {number} idVisita
 * @returns {Promise<AxiosResponse<any>>}
 */
export function startLunch(idVisita) {
  return api().put(`/${idVisita}`, {
    inicioAlmuerzo: format(new Date(), 'HH:mm:ss'),
  });
}

/**
 * Solicitar fin de horario de almuerzo
 * @param {number} idVisita
 * @returns {Promise<AxiosResponse<any>>}
 */
export function finishLunch(idVisita) {
  return api().put(`/${idVisita}`, {
    finAlmuerzo: format(new Date(), 'HH:mm:ss'),
  });
}
