import React from 'react';
import { Box } from '@chakra-ui/core';

/**
 * Componente con estilo de tarjeta
 * @param children Componente hijo
 * @param {any} rest
 * @returns {*}
 * @constructor
 */
function Card({ children, ...rest }) {
  return (
    <Box padding={3} shadow="md" borderWidth="1px" m="6px" position="relative" {...rest}>
      {children}
    </Box>
  );
}

export { Card };
