import { apiClient, getUsername } from './apiClient';

const api = () => apiClient(`/usuarios/${getUsername()}`);

/**
 * Solicitar información del usuario
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserData() {
  return api().get('');
}
