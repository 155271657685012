import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorClosed, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Skeleton, useDisclosure, useToast } from '@chakra-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { ConfirmDialog } from '../ConfirmDialog';
import {
  isVisitaLoading,
  visitaCheckInSelector,
  visitaCheckOutSelector,
  visitaFinishLunchSelector,
  visitaPdvSelector,
  visitaStartLunchSelector,
} from '../../selectors/VisitasSelector';
import { doCheckIn, doCheckOut } from '../../actions/visitasActions';
import { GPS_TOLERANCE } from '../../config';

/**
 * Botón de checkin y checkout
 * @param {number} idVisita id de la visita
 * @param {object} deviceLocation ubicación del dispositivo
 * @param {any} rest propiedades restantes heredadas
 * @returns {null|*}
 * @constructor
 */
export function CheckInCheckOutBtn({ idVisita, deviceLocation, ...rest }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const checkIn = useSelector((state) => visitaCheckInSelector(state, idVisita));
  const checkOut = useSelector((state) => visitaCheckOutSelector(state, idVisita));
  const pdv = useSelector((state) => visitaPdvSelector(state, idVisita));
  const lunchStartedAt = useSelector((state) => visitaStartLunchSelector(state, idVisita));
  const lunchFinishedAt = useSelector((state) => visitaFinishLunchSelector(state, idVisita));
  const isLoading = useSelector((state) => isVisitaLoading(state, idVisita));

  const isCheckIn = checkIn == null;

  /**
   * Iniciar acción checkin o checkout al confirmar popup de confirmación
   */
  const onDialogConfirm = () => {
    if (isCheckIn) {
      dispatch(doCheckIn(idVisita));
    } else {
      dispatch(doCheckOut(idVisita));
    }
    onClose();
  };

  /**
   * Verificar que usuario este dentro en el area solicitada
   * @returns {boolean}
   */
  const verifyLocation = () => {
    if (deviceLocation === null) {
      toast({
        title: 'Error',
        description: 'No se ha podido obtener tu ubicación',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    const device = turf.point([deviceLocation.lat, deviceLocation.lng]);

    if (pdv && pdv.polygon) {
      const polygon = JSON.parse(pdv.polygon).map((x) => [x[1], x[0]]);

      // Importante agregar el punto de inicio al final. Todos los poligono deben iniciar y terminar en el mismo punto.
      polygon.push(polygon[0]);
      const poly = turf.polygon([polygon]);

      if (!turf.booleanPointInPolygon(device, poly)) {
        toast({
          title: "Error",
          description: "Tienes que estar en el area para realizar esta acción",
          status: "error",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      return true;
    }

    const pdvLocation = turf.point([pdv.latitud, pdv.longitud]);
    const distanceOK = turf.distance(pdvLocation, device, { units: 'meters' }) < GPS_TOLERANCE;

    if (!distanceOK) {
      toast({
        title: "Error",
        description: "Tienes que estar en el punto para realizar esta acción",
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    }
    return distanceOK;
  };

  /**
   * Mostrar popup de confirmación
   */
  const onBtnClick = () => {
    if (verifyLocation()) {
      onOpen();
    }
  };

  let btnText = null;
  if (checkOut) {
    btnText = 'Visita finalizada';
  } else if (checkIn) {
    btnText = 'Marcar salida';
  } else {
    btnText = 'Marcar entrada';
  }

  if (lunchStartedAt && !lunchFinishedAt) {
    return null;
  }

  return (
    <Skeleton isLoaded={!isLoading} my={1}>
      <Button
        variantColor={isCheckIn ? 'teal' : 'orange'}
        variant="outline"
        isDisabled={checkIn && checkOut}
        {...rest}
        onClick={onBtnClick}
      >
        <FontAwesomeIcon icon={isCheckIn ? faDoorOpen : faDoorClosed} />
        <Box ml={1}>{btnText}</Box>
        <ConfirmDialog
          onConfirm={onDialogConfirm}
          isOpen={isOpen}
          onClose={onClose}
          title={isCheckIn ? 'Marcar de entrada' : 'Marcar hora de salida'}
          message={isCheckIn ? '¿Quieres marcar hora de entrada?' : '¿Quieres marcar hora de salida?'}
        />
      </Button>
    </Skeleton>
  );
}
