/**
 * Obtener estado de autenticación de un usuario
 * @param {object} state
 * @returns {boolean}
 */
export const isAuthenticatedSelector = (state) => state.getIn(['auth', 'isAuthenticated']);

/**
 * Obtener estado de la solicitud de autenticación de un usuario
 * @param {object} state
 * @returns {boolean}
 */
export const isAuthFetchingSelector = (state) => state.getIn(['auth', 'isFetching']);

/**
 * Obtener mensaje de error al autenticar un usuario
 * @param {object} state
 * @returns {string}
 */
export const authErrorSelector = (state) => state.getIn(['auth', 'errorMessage']);
