import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import * as api from "../service/encuestasService";
import {
  CREAR_CONJUNTO_REQUEST, CREAR_CONJUNTO_VIATICOS_REQUEST,
  crearConjuntoFailure, crearConjuntoFailureViaticos,
  crearConjuntoSuccess, crearConjuntoSuccessViaticos,
  GET_LATEST_CONJUNTO_REQUEST,
  GET_RESPUESTAS_REQUEST,
  getLatestConjuntoFailure,
  getLatestConjuntoSuccess, getRespuestasFailure,
  getRespuestasSuccess,
  postRespuestaFailure,
  postRespuestaSuccess,
  SAVE_PREGUNTA_REQUEST
} from "../actions/respuestasActions";

function* guardarRespuesta(accion) {
  try {
    const response = yield call(api.postRespuesta, accion);
    yield put(postRespuestaSuccess({
      idPunto: accion.idPunto,
      idConjunto: accion.idConjunto,
      idEncuesta: accion.idEncuesta,
      idPregunta: accion.idPregunta,
      data: response.data
    }));
  } catch (e) {
    yield put(postRespuestaFailure({
      idPunto: accion.idPunto,
      idConjunto: accion.idConjunto,
      idEncuesta: accion.idEncuesta,
      idPregunta: accion.idPregunta,
      error: e.getMessage() || 'Error al guardar pregunta'
    }))
  }
}

function* crearConjunto(accion) {
  try {
    const response = yield call(api.crearConjunto, accion);
    yield put(crearConjuntoSuccess({
      idPunto: accion.idPunto,
      idEncuesta: accion.idEncuesta,
      data: response.data
    }));
  } catch (e) {
    yield put(crearConjuntoFailure({
      idPunto: accion.idPunto,
      idEncuesta: accion.idEncuesta,
      error: e.getMessage() || 'Error al guardar pregunta'
    }))
  }
}

function* obtenerUltimoConjunto(accion) {
  try {
    const response = yield call(api.getLatestConjunto, accion.idPunto);
    yield put(getLatestConjuntoSuccess(accion.idPunto, accion.idEncuesta, response.data));
  } catch (e) {
    yield put(getLatestConjuntoFailure(accion.idPunto, e.getMessage() || 'Error al obtener el ultimo conjunto'));
  }
}


function* getRespuestas(accion) {
  try {
    const apiResponse = yield call(api.getRespuestas, accion.idEncuesta, accion.idConjunto);
    yield put(getRespuestasSuccess(accion.idPunto, accion.idEncuesta, accion.idConjunto, apiResponse));
  } catch (e) {
    yield put(getRespuestasFailure(accion.idPunto, accion.idEncuesta, accion.idConjunto, e.getMessage() || 'Error al obtener el ultimo conjunto'));
  }
  accion.cb();
}

function* crearConjuntoViaticos(accion) {
  try {
    const response = yield call(api.crearConjuntoViaticos, accion);
    yield put(crearConjuntoSuccessViaticos({
      username: accion.username,
      idEncuesta: accion.idEncuesta,
      data: response.data,
      newIdentifier: accion.newIdentifier
    }));
  } catch (e) {
    yield put(crearConjuntoFailureViaticos({
      username: accion.username,
      idEncuesta: accion.idEncuesta,
      error: e.getMessage() || 'Error al guardar pregunta'
    }))
  }
}

export default function* respuestaSaga() {
  yield takeEvery(SAVE_PREGUNTA_REQUEST, guardarRespuesta);
  yield takeLatest(CREAR_CONJUNTO_REQUEST, crearConjunto);
  yield takeLatest(GET_LATEST_CONJUNTO_REQUEST, obtenerUltimoConjunto);
  yield takeLatest(GET_RESPUESTAS_REQUEST, getRespuestas);
  yield takeLatest(CREAR_CONJUNTO_VIATICOS_REQUEST, crearConjuntoViaticos)
}
