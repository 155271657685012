import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  ALL_ENCUESTAS_REQUEST,
  GET_ENCUESTA_SECCIONES_REQUEST,
  GET_SECCION_PREGUNTAS_REQUEST,
  getAllEncuestasFailure,
  getAllEncuestasSuccess,
  getEncuestaSeccionesFailure,
  getEncuestaSeccionesSuccess,
  getSeccionPreguntasFailure,
  getSeccionPreguntasSuccess
} from "../actions/encuestasActions";
import * as api from '../service/encuestasService'

function* getAllEncuestas(action) {
  try {
    const response = yield  call(api.getAllEncuestas);
    yield put(getAllEncuestasSuccess(response.data))
  } catch (e) {
    yield put(getAllEncuestasFailure(e.message || 'Error al obtener encuestas'))
  }
}

function* getEncuestaSecciones(action) {
  try {
    const response = yield call(api.getEncuestaSecciones, action.idEncuesta, action.idVersion);
    yield put(getEncuestaSeccionesSuccess(action.idEncuesta, response.data));
  } catch (e) {
    yield put(getEncuestaSeccionesFailure(e.message || 'Error al obtener secciones'));
  }
}

function* getPreguntas(action) {
  try {
    const response = yield call(api.getEncuestaSeccionPreguntas, action.idEncuesta, action.idVersion, action.idSeccion);
    yield put(getSeccionPreguntasSuccess({
      idEncuesta: action.idEncuesta,
      idSeccion: action.idSeccion,
      data: response.data
    }));
  } catch (e) {
    yield put(getSeccionPreguntasFailure(e.message || 'Error al obtener preguntas'))
  }
  if (action.cb) {
    action.cb();
  }
}

export default function* encuestasSaga() {
  yield takeLatest(ALL_ENCUESTAS_REQUEST, getAllEncuestas);
  yield takeLatest(GET_ENCUESTA_SECCIONES_REQUEST, getEncuestaSecciones)
  yield takeEvery(GET_SECCION_PREGUNTAS_REQUEST, getPreguntas)
}
