import {fromJS} from "immutable";
import * as actions from '../actions/respuestasActions'

const initState = fromJS({});

export default function respuestasReducer(state = initState, action) {
  switch (action.type) {
    case actions.SAVE_PREGUNTA_REQUEST:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "respuestas": {
              [action.idPregunta]: {
                error: null,
                isLoading: true,
                valor: action.valor,
                opciones: action.opciones,
                idConjunto: action.idConjunto
              }
            }
          }
        }
      });
    case actions.SAVE_PREGUNTA_FAILURE:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "respuestas": {
              [action.idPregunta]: {
                isLoading: false,
                error: action.error,
              }
            }
          }
        }
      });
    case actions.SAVE_PREGUNTA_SUCCESS:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "respuestas": {
              [action.idPregunta]: {
                error: null,
                isLoading: false
              }
            }
          }
        }
      });
    case actions.CREAR_CONJUNTO_REQUEST:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: null,
              isLoading: true,
              error: null,
            }
          }
        }
      });
    case actions.CREAR_CONJUNTO_SUCCESS:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "conjunto": {
              ...action.data, idConjunto: action.data.conjunto_id, isLoading: false, error: null,
            }
          }
        }
      });
    case actions.CREAR_CONJUNTO_FAILURE:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: null,
              isLoading: false,
              error: action.data,
            }
          }
        }
      });

    case actions.GET_LATEST_CONJUNTO_SUCCESS:
      const latest = action.data.pop();
      let conjuntoId = 0;
      if (latest) {
        conjuntoId = latest.conjunto_id || 0;
      }
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: conjuntoId,
              isLoading: false,
              error: null,
              ...latest
            }
          }
        }
      });

    case actions.GET_LATEST_CONJUNTO_FAILURE:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: null,
              isLoading: false,
              error: action.error,
            }
          }
        }
      });

    case actions.GET_LATEST_CONJUNTO_REQUEST:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: null,
              isLoading: false,
              error: null
            }
          }
        }
      });

    case actions.GET_RESPUESTAS_REQUEST:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "respuestas": {}
          }
        }
      });
    case actions.GET_RESPUESTAS_FAILURE:
      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "respuestas": {
              error: action.error
            }
          }
        }
      });
    case actions.GET_RESPUESTAS_SUCCESS:
      if (!action.data) {
        return state;
      }

      let respuestas = {};

      action.data.forEach(x => {
        respuestas[x.pregunta_id] = {
          error: null,
          isLoading: false,
          ...x,
          opciones: x.opciones,
          idConjunto: action.idConjunto
        };
      });

      return state.mergeDeep({
        [action.idPunto]: {
          [action.idEncuesta]: {
            "respuestas": respuestas
          }
        }
      });

    case actions.CREAR_CONJUNTO_VIATICOS_REQUEST:
      return state.mergeDeep({
        [action.username]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: null,
              isLoading: true,
              error: null,
            }
          }
        }
      });
    case actions.CREAR_CONJUNTO_VIATICOS_SUCCESS:
      return state.mergeDeep({
        [action.username]: {
          [action.idEncuesta]: {
            "conjunto": {
              ...action.data, idConjunto: action.data.conjunto_id, isLoading: false, error: null, newIdentifier: action.newIdentifier
            }
          }
        }
      });
    case actions.CREAR_CONJUNTO_VIATICOS_FAILURE:
      return state.mergeDeep({
        [action.username]: {
          [action.idEncuesta]: {
            "conjunto": {
              idConjunto: null,
              isLoading: false,
              error: action.data,
            }
          }
        }
      });
    case actions.CLEAR_RESPUESTAS:
      return state.setIn([action.idPunto, action.idEncuesta, "respuestas"], []);

    default:
      return state;
  }
}
