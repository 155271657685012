import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../service/usuarioService';
import { GET_USER_DATA_REQUEST, getUserDataFailure, getUserDataSuccess } from '../actions/userActions';

/**
 * Obtener datos del usuario
 * @returns {Generator<<"PUT", PutEffectDescriptor<{type: string, message: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>, void, *>}
 */
function* userWorker() {
  try {
    const response = yield call(api.getUserData);
    yield put(getUserDataSuccess(response.data));
  } catch (e) {
    yield put(getUserDataFailure(e.message || 'Error al obtener información del usuario'));
  }
}

/**
 * Observar todas las acciones emitidas de tipo usuario
 * @returns {Generator<<"FORK", ForkEffectDescriptor<any>>, void, *>}
 */
export function* userWatcherSaga() {
  yield takeLatest(GET_USER_DATA_REQUEST, userWorker);
}
