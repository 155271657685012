import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Flex, Heading, Skeleton, Text, useToast } from "@chakra-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {getVisitaById} from "../../actions/visitasActions";
import Map from "../Location/Map";
import {GPS} from "../Location/GPS";
import {CheckInCheckOutBtn} from "../Button/CheckInCheckOutBtn";
import {LunchBtn} from "../Button/LunchBtn";
import {
  isVisitaLoading,
  visitaByIdSelector,
} from "../../selectors/VisitasSelector";
import {GPS_MAX_AGE} from "../../config";
import EncuestasBtn from "../Button/EncuestasBtn";

/**
 * Visita programada
 * @returns {*}
 * @constructor
 */
export function Visita() {
  const dispatch = useDispatch();
  const toast = useToast();
  const {idVisita} = useParams();
  const isLoading = useSelector((state) => isVisitaLoading(state, idVisita));
  const visita = useSelector((state) => visitaByIdSelector(state, idVisita));

  const [deviceLocation, setLocation] = useState({
    latitude: null,
    longitude: null,
    accuracy: null,
  });

  useEffect(() => {
    dispatch(getVisitaById(idVisita));
  }, []);

  /**
   * Notificar que no se pudo obtener ubicación del dispositivo
   * @param msg
   */
  const onLocationError = (msg) => {
    toast({
      title: "Error",
      description: msg,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  /**
   * Actualizar estado de ubicación
   * @param location
   */
  const onLocationChange = (location) => {
    setLocation(location);
  };

  const pdvName = visita ? visita.pdv.nombre : "-";
  const municipioDepartamento = visita
    ? `${visita.pdv.municipio}, ${visita.pdv.departamento}`
    : "-";

  const mapMarkers = [];
  let pdvLatLong = null;
  if (visita) {
    pdvLatLong = {
      lat: visita.pdv.latitud,
      lng: visita.pdv.longitud,
    };
    mapMarkers.push({
      title: visita.pdv.nombre,
      name: "TargetPoint",
      location: pdvLatLong,
    });
  }

  let currentLatLong = null;
  if (deviceLocation && deviceLocation.longitude && deviceLocation.latitude) {
    currentLatLong = {
      lat: deviceLocation.latitude,
      lng: deviceLocation.longitude,
    };
    mapMarkers.push({
      title: "Ubicación actual",
      name: "CurrentLocation",
      location: currentLatLong,
    });
  }

  let polygonCords = null;
  if (visita && visita.pdv.polygon) {
    const polyGonArr = JSON.parse(visita.pdv.polygon) || [];
    polygonCords = polyGonArr.map((x) => {
      return {
        lat: x[1],
        lng: x[0],
      };
    });
  }

  return (
    <Flex direction="row" flexWrap="wrap" height="100%">
      <Box width={["100%", "100%", "45%", "35%", "25%"]}>
        <Box p={5}>
          <Skeleton isLoaded={!isLoading}>
            <Heading textAlign="center">{pdvName}</Heading>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} my={1}>
            <Text textAlign="center">
              <FontAwesomeIcon icon={faMapMarkerAlt}/> {municipioDepartamento}
            </Text>
          </Skeleton>
          <CheckInCheckOutBtn
            width="100%"
            idVisita={idVisita}
            deviceLocation={currentLatLong}
          />
          <LunchBtn idVisita={idVisita} mt={2} width="100%"/>
          <EncuestasBtn idVisita={idVisita} mt={2} width="100%"/>
        </Box>
        <Box textAlign="center" mb={2}>
          <GPS
            showIcon
            showLocation
            onChange={onLocationChange}
            onError={onLocationError}
            gpsInterval={GPS_MAX_AGE}
          />
        </Box>
      </Box>
      <Box
        position="relative"
        width={["100%", "100%", "55%", "65%", "75%"]}
        height="calc(100vh - 50px)"
      >
        {pdvLatLong ? (
          <Map
            initialCenter={pdvLatLong}
            markers={mapMarkers}
            polygon={polygonCords}
          />
        ) : null}
      </Box>
    </Flex>
  );
}
