import React from 'react';
import { GoogleApiWrapper, Map, Marker, Polygon } from 'google-maps-react';
import { GOOGLE_MAPS_KEY } from '../../config';

/**
 * Mapa de GoogleMaps
 */
export class MapContainer extends React.Component {
  render() {
    const mapBounds = new this.props.google.maps.LatLngBounds();
    (this.props.bounds || []).forEach((bound) => {
      mapBounds.extend(bound);
    });

    const markers = (this.props.markers || []).map(function (markerData) {
      return (
        <Marker key={markerData.name} title={markerData.title} name={markerData.name} position={markerData.location} />
      );
    });

    // Crear poligono si se provee el geoJson
    let polygon = null;
    if (this.props.polygon) {
      polygon = (
        <Polygon
          paths={this.props.polygon}
          strokeColor="#2a69ac"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#4299e1"
          fillOpacity={0.35}
        />
      );
    }

    return (
      <Map google={this.props.google} bounds={mapBounds.length ? mapBounds : null} width="100%" {...this.props}>
        {markers}
        {polygon}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_KEY,
})(MapContainer);
