import React from 'react';
import { Box } from '@chakra-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * FontAwesome icon wrapper
 * @param icon
 * @param {any} rest
 * @returns {*}
 * @constructor
 */
export function FontAwesomeIconBox({ icon, ...rest }) {
  return (
    <Box {...rest}>
      <FontAwesomeIcon icon={icon} display="inline-block" />
    </Box>
  );
}
