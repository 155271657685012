import React, {useEffect} from "react";
import {Radio, RadioGroup} from "@chakra-ui/core";

export default function PreguntaRadioButton({value, opciones, onChange, init}) {
  const [currentValue, setCurrentValue] = React.useState(value || 0);

  useEffect(() => {
    init({id_opcion: currentValue}, "radiogroup");
  }, []);

  const items = opciones.map(o => {
    return <Radio key={Number(o.idOpcion)} value={o.idOpcion}>{o.valor}</Radio>;
  });

  return (
    <RadioGroup onChange={e => {
      const val = Number(e.target.value);
      setCurrentValue(val);
      if (onChange) {
        onChange({id_opcion: val});
      }
    }} value={currentValue}>
      {items}
    </RadioGroup>
  );
}
