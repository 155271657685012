import {all} from 'redux-saga/effects';
import {authWatcherSaga} from './authSaga';
import {userWatcherSaga} from './userSaga';
import {visitasWatcherSaga} from './visitasSaga';
import encuestasSaga from "./encuestasSaga";
import respuestaSaga from "./respuestaSaga";
import viaticosSaga from "./viaticosSaga";
import prospeccionesSaga from "./prospeccionesSaga";
import oneDriveSaga from "./oneDriveSaga";

/**
 * Combinar todos los saga watchers
 * @returns {Generator<<"ALL", Generator<<"FORK", ForkEffectDescriptor>, void, *>|Generator<*|<"FORK", ForkEffectDescriptor>, void, *>>, void, *>}
 */
export function* rootSaga() {
  yield all([authWatcherSaga(), userWatcherSaga(), visitasWatcherSaga(), encuestasSaga(), respuestaSaga(), viaticosSaga(), prospeccionesSaga(), oneDriveSaga()]);
}
