import {VIATICOS_ENCUESTA_IDS} from "../config";

export const getAllEncuestasSelector = (state) => state.getIn(['encuestas', 'collection']);

export const getAllStdEncuestasSelector = (state) => state.getIn(['encuestas', 'collection']).filter(e => !VIATICOS_ENCUESTA_IDS.includes(e.idEncuesta));

export const getEncuestasViaticos = (state) => state.getIn(['encuestas', 'collection']).filter(e => VIATICOS_ENCUESTA_IDS.includes(e.idEncuesta));

export const getEncuestaByIdSelector = (state, idEncuesta) => {
  // Es importante esta conversión ya que el valor puede ser numérico dependiendo si proviene o no de la url.
  const id = Number(idEncuesta);

  const index = state.getIn(['encuestas', 'collection']).findIndex((x) => {
    return x.idEncuesta === id;
  });
  //const index = -1;

  if (index === -1) {
    return null;
  }

  return state.getIn(['encuestas', 'collection', index]);
};

export const getViaticosSelector = (state) => state.getIn(['viaticos', 'collection']);
