import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Flex, Heading, useToast} from "@chakra-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getEncuestaByIdSelector} from "../../selectors/EncuestasSelector";
import {useParams} from 'react-router-dom';
import {getAllEncuestas, getEncuestaSecciones, getSeccionPreguntas} from "../../actions/encuestasActions";
import SectionContainer from "../Encuesta/SectionContainer";
import {getConjuntoSelector, getRespuestasSelector} from "../../selectors/respuestaSelector";
import {crearConjunto, getLatestConjunto, getRespuestas} from "../../actions/respuestasActions";
import { useHistory } from "react-router-dom";


export default function Encuesta() {
  const dispatch = useDispatch();
  const {idVisita, idEncuesta} = useParams();
  const encuesta = useSelector(state => getEncuestaByIdSelector(state, idEncuesta));
  const conjunto = useSelector(state => getConjuntoSelector(state, idVisita, idEncuesta));

  const [loadingPreguntas, setLoadingPreguntas] = useState(false);
  const [preguntaDone, setPreguntasDone] = useState(false);
  const [loadingRespuestas, setLoadingRespuestas] = useState(false);
  const [respuestasDone, setRespuestasDone] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const respuestas = useSelector(state => getRespuestasSelector(state, conjunto ? conjunto.punto_cronograma_id : 0, encuesta ? encuesta.idEncuesta : 0));

  useEffect(() => {
    if (conjunto) {
      return;
    }
    dispatch(getLatestConjunto(idVisita, idEncuesta));
  }, [conjunto]);

  useEffect(() => {
    if (encuesta) {
      return;
    }
    dispatch(getAllEncuestas());
  }, [encuesta]);

  useEffect(() => {
    if (!conjunto || conjunto.idConjunto !== 0) {
      return;
    }

    dispatch(crearConjunto({idPunto: idVisita, idEncuesta, versionEncuesta: encuesta.currentVersion}))
  }, [conjunto, encuesta])

  useEffect(() => {
    if (!encuesta || encuesta.secciones) {
      return;
    }

    dispatch(getEncuestaSecciones({idEncuesta, idVersion: encuesta.currentVersion}));
  }, [encuesta]);

  useEffect(() => {
    if (!encuesta || !encuesta.secciones || preguntaDone) {
      return;
    }

    const allLoaded = encuesta.secciones.filter(x => {
      return x.preguntas;
    });

    if (allLoaded.length === encuesta.secciones.length) {
      setPreguntasDone(true);
      return;
    }

    if (loadingPreguntas) {
      return;
    }

    const timer = setTimeout(() => {
      encuesta.secciones.forEach(s => {
        dispatch(getSeccionPreguntas({
          idEncuesta,
          idVersion: encuesta.currentVersion,
          idSeccion: s.idSeccion,
        }))
      });
    }, 1000);

    setLoadingPreguntas(true);
    return () => clearTimeout(timer);
  }, [encuesta]);


  useEffect(() => {
    if (!conjunto || !encuesta || !encuesta.secciones) {
      return;
    }

    if (!preguntaDone || loadingRespuestas || respuestasDone) {
      return;
    }

    const timer = setTimeout(() => {
      dispatch(getRespuestas(idVisita, idEncuesta, conjunto.idConjunto, () => {
        setLoadingRespuestas(false);
        setRespuestasDone(true);
      }));
    }, 1000);

    setLoadingRespuestas(true);
    return () => clearTimeout(timer);
  }, [conjunto, encuesta, preguntaDone]);

  if (!conjunto || !encuesta || !encuesta.secciones || !respuestasDone) {
    return <Box textAlign="center" m={10}>
      <CircularProgress isIndeterminate color="green"/>
    </Box>;
  }

  const handleFinish = () => {
    const mainPreguntas = [].concat.apply([], encuesta.secciones.map(s => s.preguntas)).filter(x => !x.idPadre);
    const answerCount = (mainPreguntas.filter(p => respuestas[p.idPregunta]) || []).length;
    if (mainPreguntas.length !== answerCount) {
      toast({
        title: "Completa los datos",
        description: "Hay datos pendientes de completar",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }else {
      history.push(`/visita/${idVisita}/encuestas/${idEncuesta}/versiones/${encuesta.currentVersion}/conjunto/${conjunto.idConjunto}/success`);
    }
  };

  return (
    <Flex direction="row" flexWrap="wrap" height="100%">
      <Box width={["100%", "100%", "45%", "35%", "25%"]}>
        <Heading as="h3" size="lg" textAlign="center" mt={5} mb={5}>{encuesta.nombre}</Heading>
        <SectionContainer secciones={encuesta.secciones} conjunto={conjunto} encuesta={encuesta}
                          respuestas={respuestas}/>
        <br/>
        <Box p={5}>
          <Button variantColor="green" w="100%" onClick={handleFinish}>Finalizar</Button>
        </Box>
      </Box>
    </Flex>
  );
}
