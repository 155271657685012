import { fromJS } from 'immutable';
import * as actions from '../actions/visitasActions';

// Estado inicial inmutable
const initialState = fromJS({
  fetchingIds: {},
  isFetching: false,
  errorMessage: null,
  collection: [],
});

/**
 * Actualizar estado de una visita en la colección de visitas
 * @param state Estado a actualizar
 * @param visita Nuevo estado de visita
 * @returns {Cursor | this | this | (string|*)[] | this | this | this | this}
 */
const updateVisitaInCollection = (state, visita) => {
  const index = state.get("collection").findIndex((x) => x.id === visita.id);
  return state.setIn(["collection", index], visita);
};

/**
 * Reducer encargado de los estados de autenticación
 * @param {object} state estado actual
 * @param {object} action acción emitida por el action creator
 * @returns {object} nuevo estado
 */
export function visitasReducer(state = initialState, action) {
  switch (action.type) {
    //region ALL_VISITAS_REQUEST
    case actions.ALL_VISITAS_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null,
      });

    case actions.ALL_VISITAS_FAILURE_REQUEST:
      return state.merge({
        isFetching: false,
        errorMessage: action.message,
      });

    case actions.ALL_VISITAS_SUCCESS_REQUEST:
      return state.merge({
        isFetching: false,
        errorMessage: null,
        collection: action.data,
      });
    //endregion

    //region VISITA_BY_ID_REQUEST
    case actions.VISITA_BY_ID_REQUEST:
      return state.merge({
        fetchingIds: { [action.idVisita]: true },
        errorMessage: null,
      });

    case actions.VISITA_BY_ID_FAILURE:
      return state.merge({
        fetchingIds: { [action.idVisita]: false },
        errorMessage: action.message,
      });

    case actions.VISITA_BY_ID_SUCCESS:
      return updateVisitaInCollection(state, action.data).merge({
        fetchingIds: { [action.data.id]: false },
        errorMessage: null,
      });
    //endregion

    //region DO_CHECK_OUT_SUCCESS
    case actions.DO_CHECK_OUT_SUCCESS:
      return updateVisitaInCollection(state, action.data).merge({
        fetchingIds: { [action.data.id]: false },
        errorMessage: null,
      });

    case actions.DO_CHECK_OUT_FAILURE:
      return state.merge({
        fetchingIds: { [action.idVisita]: false },
        errorMessage: action.message,
      });

    case actions.DO_CHECK_OUT_REQUEST:
      return state.merge({
        fetchingIds: { [action.idVisita]: true },
        errorMessage: null,
      });
    //endregion

    //region DO_CHECK_IN_SUCCESS
    case actions.DO_CHECK_IN_SUCCESS:
      return updateVisitaInCollection(state, action.data).merge({
        fetchingIds: { [action.data.id]: false },
        errorMessage: null,
      });
    case actions.DO_CHECK_IN_FAILURE:
      return state.merge({
        fetchingIds: { [action.idVisita]: false },
        errorMessage: action.message,
      });
    case actions.DO_CHECK_IN_REQUEST:
      return state.merge({
        fetchingIds: { [action.idVisita]: true },
        errorMessage: null,
      });
    //endregion

    //region START_LUNCH_SUCCESS
    case actions.START_LUNCH_SUCCESS:
      return updateVisitaInCollection(state, action.data).merge({
        fetchingIds: { [action.data.id]: false },
        errorMessage: null,
      });

    case actions.START_LUNCH_FAILURE:
      return state.merge({
        fetchingIds: { [action.idVisita]: false },
        errorMessage: action.message,
      });

    case actions.START_LUNCH_REQUEST:
      return state.merge({
        fetchingIds: { [action.idVisita]: true },
        errorMessage: null,
      });
    //endregion

    //region FINISH_LUNCH_SUCCESS
    case actions.FINISH_LUNCH_SUCCESS:
      return updateVisitaInCollection(state, action.data).merge({
        fetchingIds: { [action.data.id]: false },
        errorMessage: null,
      });

    case actions.FINISH_LUNCH_FAILURE:
      return state.merge({
        fetchingIds: { [action.idVisita]: false },
        errorMessage: action.message,
      });

    case actions.FINISH_LUNCH_REQUEST:
      return state.merge({
        fetchingIds: { [action.idVisita]: true },
        errorMessage: null,
      });
    //endregion
    default:
      return state;
  }
}
