import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../Card';
import { login } from '../../actions/authActions';
import { authErrorSelector, isAuthenticatedSelector, isAuthFetchingSelector } from '../../selectors/AuthSelectors';

/**
 * Componente para login
 * @returns {*}
 * @constructor
 */
export function Login() {
  const [credentials, setCredentials] = useState({
    username: null,
    password: null,
  });

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => isAuthenticatedSelector(state));
  const loginInProgress = useSelector((state) => isAuthFetchingSelector(state));
  const errorMessage = useSelector((state) => authErrorSelector(state));

  /**
   * Cambiar credenciales
   * @param e Evento click del botón
   */
  const onCredentialsChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  /**
   * Iniciar acción de login
   * @returns {*}
   */
  const onFormSubmit = () => dispatch(login(credentials));

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Box width="100%" height="100%" background="#191e30" position="absolute">
      <Card
        background="white"
        rounded="md"
        width={['290px', '360px']}
        mr="auto"
        ml="auto"
        top="50%"
        transform="translateY(-75%)"
      >
        <Image src="/insight-logo-basic.png" alt="Insight BTL" width="215px" heiht="auto" mr="auto" ml="auto" />

        <Heading textAlign="center">Insight BTL</Heading>

        <FormControl isInvalid={errorMessage && errorMessage !== ''} isDisabled={loginInProgress}>
          <FormLabel htmlFor="email">Usuario</FormLabel>
          <InputGroup>
            <InputLeftElement>
              <FontAwesomeIcon icon={faUser} />
            </InputLeftElement>
            <Input type="text" id="username" name="username" onChange={onCredentialsChange} />
          </InputGroup>
        </FormControl>
        <FormControl mt={2} isInvalid={errorMessage && errorMessage !== ''} isDisabled={loginInProgress}>
          <FormLabel htmlFor="password">Contraseña</FormLabel>
          <InputGroup>
            <InputLeftElement>
              <FontAwesomeIcon icon={faKey} />
            </InputLeftElement>
            <Input type="password" id="password" name="password" onChange={onCredentialsChange} />
          </InputGroup>
        </FormControl>

        <Box textAlign="right">
          <Button
            mt={5}
            variantColor="teal"
            onClick={onFormSubmit}
            rightIcon="arrow-forward"
            isLoading={loginInProgress}
            isDisabled={loginInProgress || !credentials.username || !credentials.password}
          >
            Iniciar sesión
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
