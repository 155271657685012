import React, {useEffect, useState} from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox, CheckboxGroup,
  CircularProgress,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  useToast
} from "@chakra-ui/core";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
  allDepartamentosSelector,
  errorSelector,
  isAddingSelector,
  isSavedSelector,
  municipiosDepartamentoSelector
} from "../../selectors/ProspeccionesSelector";
import {addProspeccion, getDepartamentos, getMunicipios} from "../../actions/prospeccionesActions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Prospecciones() {
  const dispatch = useDispatch();
  const query = useQuery();
  const toast = useToast();

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [tipoPunto, setTipoPunto] = useState(null);
  const [idDepartamento, setIdDepartamento] = useState(null);
  const [idMunicipio, setIdMunicipio] = useState(null);
  const [otrosBancos, setOtrosBancos] = useState([]);

  const departamentos = useSelector(allDepartamentosSelector);
  const municipios = useSelector(state => municipiosDepartamentoSelector(state, idDepartamento));
  const adding = useSelector(isAddingSelector);
  const isSaved = useSelector(isSavedSelector)
  const error = useSelector(errorSelector);

  const otrosBancosData = [
    {'name': 'Tigo money', code: 'tigo-money'},
    {'name': 'MiCoope', code: 'micoope'},
    {'name': 'Banco Industrial', code: 'bi'},
    {'name': 'BAC', code: 'bac'},
    {'name': 'BAM', code: 'bam'},
    {'name': 'Banco inmobiliario', code: 'banco-inmobiliario'},
    {'name': 'Promerica', code: 'promerica'},
    {'name': 'Génesis empresarial', code: 'genesis-empresarial'},
    {'name': 'Otro', code: 'otro'},
  ];

  useEffect(() => {
    if (!departamentos) {
      dispatch(getDepartamentos());
    }
  }, []);

  useEffect(() => {
    if (!idDepartamento) {
      return;
    }
    dispatch(getMunicipios(idDepartamento));
  }, [idDepartamento]);

  if (!departamentos) {
    return <Box textAlign="center" m={10}>
      <CircularProgress isIndeterminate color="green"/>
    </Box>;
  }

  const departamentosList = Object.values(departamentos || {})
    .map(depto => {
      return <option value={depto.id} key={`depto-${depto.id}}`}>{depto.name}</option>;
    });

  const municipiosList = Object.values(municipios || {})
    .map(mun => {
      return <option value={mun.id} key={`mun-${mun.id}`}>{mun.name}</option>;
    })

  const canSave = name.length && address.length && idDepartamento !== null && idMunicipio !== null;

  const submitHandle = () => {
    dispatch(addProspeccion({
      username: query.get('user'),
      idMunicipio: idMunicipio,
      name: name,
      latitude: query.get('latitude'),
      longitude: query.get('longitude'),
      address: address,
      typeCode: tipoPunto,
      otrosBancos: otrosBancos.join(',')
    }))
  };

  const successAlert = <Alert status="success" mt={5}>
    <AlertIcon/>
    <div>
      Prospección guardada! <br/>
      <b>Recuerda sincronizar</b> para poder visualizar el nuevo punto.
    </div>
  </Alert>;

  const otrosBancosCheckboxes = otrosBancosData.map(x => <Checkbox value={x.code}
                                                                   key={`chx-${x.code}`}>{x.name}</Checkbox>)

  if (error) {
    toast({
      title: "Error",
      description: error,
      status: "error",
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Box width="100%" height="100%" background="#fff" position="absolute">
      <Box margin={5}>
        <Heading textAlign="center">Prospección</Heading>
        <Alert status="warning" mt={5} mb={10}>
          <AlertIcon size="40px"/>
          <div>
            Recuerda que sólo puedes crear <b>ubicaciones nuevas</b>, si la prospección no es un punto nuevo,
            debes contactar a tu supervisor para que sea asignado a tu cronograma.
          </div>
        </Alert>
        <FormControl>
          <FormLabel>Nombre</FormLabel>
          <Input type="text" value={name} onChange={e => setName(e.target.value)}/>
        </FormControl>
        <FormControl>
          <FormLabel>Dirección</FormLabel>
          <Input type="text" value={address} onChange={e => setAddress(e.target.value)}/>
        </FormControl>
        <FormControl>
          <FormLabel>Tipo de punto</FormLabel>
          <Select placeholder="Selecciona un tipo de punto" isDisabled={!name || !address} value={tipoPunto || ''}
                  onChange={(e => setTipoPunto(e.target.value))}>
            <option value="prospection" key="prospection">Prospección G&T</option>
            <option value="bru" key="bru">Caja rural</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Departamento</FormLabel>
          <Select placeholder="Selecciona departamento" isDisabled={!departamentos || !tipoPunto}
                  value={idDepartamento || ''}
                  onChange={(e => setIdDepartamento(e.target.value))}>
            {departamentosList}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Municipio</FormLabel>
          <Select placeholder="Selecciona municipio" isDisabled={!departamentos || !municipios}
                  value={idMunicipio || ''}
                  onChange={e => setIdMunicipio(e.target.value)}>
            {municipiosList}
          </Select>
        </FormControl>
        <FormControl hidden={tipoPunto !== "bru"}>
          <FormLabel>¿Con qué otros bancos comparte la caja rural?</FormLabel>
          <CheckboxGroup spacing={1} defaultValue={otrosBancos} onChange={setOtrosBancos}>
            {otrosBancosCheckboxes}
          </CheckboxGroup>
        </FormControl>
        {isSaved ? successAlert : null}
        <Button variantColor="teal" mt={5} isDisabled={!canSave || isSaved} width="100%" onClick={submitHandle}
                isLoading={adding}>
          Guardar
        </Button>
      </Box>
    </Box>
  );
}
