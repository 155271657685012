import React from "react";

import {Accordion,} from "@chakra-ui/core";
import SectionAccordion from "./SectionAccordion";
import {VIATICOS_ENCUESTA_IDS} from "../../config";
import SectionSinglePage from "./SectionSinglePage";

export default function SectionContainer({secciones, conjunto, encuesta, respuestas}) {

  if (!secciones.length) {
    return null;
  }

  const items = secciones.map(section => {
    const mainPreguntas = section.preguntas.filter(x => !x.idPadre);
    let answerCount = 0;

    mainPreguntas.forEach(p => {
      if (respuestas[p.idPregunta]) {
        answerCount++;
      }
    });

    if (VIATICOS_ENCUESTA_IDS.includes(encuesta.idEncuesta)) {
      return <SectionSinglePage key={section['idSeccion']}  title={section.titulo} preguntas={section.preguntas}
                                 conjunto={conjunto} encuesta={encuesta} respuestas={respuestas}/>
    }

    let progress = 0;
    if (answerCount > 0 && mainPreguntas.length > 0) {
      progress = Number(100 * answerCount / mainPreguntas.length).toFixed(0);
    }
    return <SectionAccordion key={section['idSeccion']} title={section.titulo} preguntas={section.preguntas}
                             conjunto={conjunto} encuesta={encuesta} progress={progress}/>
  });

  return (
    <Accordion>
      {items}
    </Accordion>
  );
}
