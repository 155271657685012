import { fromJS } from 'immutable';
import * as actions from '../actions/authActions';

// Estado inicial inmutable
const initialState = fromJS({
  isFetching: false,
  isAuthenticated: !!localStorage.getItem('token'),
  errorMessage: null,
});

/**
 * Reducer encargado de los estados de autenticación
 * @param {object} state estado actual
 * @param {object} action acción emitida por el action creator
 * @returns {object} nuevo estado
 */
export function authReducer(state = initialState, action) {
  switch (action.type) {
    //region LOGIN
    case actions.LOGIN_REQUEST:
      return state.merge({
        isFetching: true,
        errorMessage: null,
      });

    case actions.LOGIN_SUCCESS:
      return state.merge({
        isFetching: false,
        isAuthenticated: true,
        errorMessage: null,
      });

    case actions.LOGIN_FAILURE:
      return state.merge({
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    //endregion

    case actions.LOGOUT:
      return state.merge({
        isFetching: false,
        isAuthenticated: false,
        errorMessage: null,
      });
    default:
      return state;
  }
}
