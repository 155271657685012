import React from "react";
import styled from '@emotion/styled'

const TableStyled = styled.table`
  border-width: 1px 0;
  border-style: solid;
  border-color: #ddd;
  width: 100%;
  
  & > * > tr {
    border-width: 1px 0;
    border-style: solid;
    boer-color: #ddd;
  }
  
  & > tbody > tr:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  } 
  
  & > thead > tr > th {
    text-align: left;
  }
  
  & > thead th,
  & > tbody td {
    padding: 5px 15px;
  }
`;


export default function Table({
  columns = [], data = [], onRowClick = () => {
  }
}) {

  const headers = columns.map(c => <th key={c.key}>{c.label}</th>);

  const rows = data.map(rowData => {

    const row = columns.map(columnSettings => {
      const value = rowData[columnSettings.key] || null;

      if (typeof columnSettings.render === 'function') {
        return <td key={`td-${columnSettings.key}-${rowData.id}`}>
          {columnSettings.render(value, rowData)}
        </td>;
      }

      return <td key={`td-${columnSettings.key}-${rowData.id}`}>
        {value}
      </td>
    })

    return <tr onClick={() => onRowClick(rowData)} key={`tr-${rowData.id}`}>{row}</tr>
  });

  return (
    <TableStyled>
      <thead>
      <tr>
        {headers}
      </tr>
      </thead>
      <tbody>
      {rows}
      </tbody>
    </TableStyled>
  );
}
