import React, { useEffect, useState } from "react";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { Tag, TagLabel, Spinner } from "@chakra-ui/core";
import { FontAwesomeIconBox } from "../FontAwesomeIconBox";
import { format } from "date-fns";

/**
 * Solicitar ubicación del dispositivo de forma constante
 * @param {boolean} showIcon false si se quiere tomar GPS de forma silenciosa sin mostrar icono de GPS.
 * @param {function} onChange función a ejecutar cuando las coordenadas cambien
 * @param {function} onError función a ejecutar cuando las falla al tomar la ubicación
 * @param {boolean} showLocation true para mostrar las coordenadas al lado del icono de gps
 * @param {number} maximumAge Vejez maxima permitida para una ubicación
 * @returns {*}
 * @constructor
 */
export function GPS({
  showIcon = true,
  onChange,
  onError,
  showLocation = false,
  maximumAge = 60000,
}) {
  if (!("geolocation" in navigator)) {
    onError("GeoPosicionamiento no es soportado en este navegador");
  }

  const [location, setLocation] = useState(null);

  /**
   * Notificar cambio de coordenadas
   * @param {object} newLocation
   */
  const updateLocation = (newLocation) => {
    onChange(newLocation);
    setLocation(newLocation);
  };

  useEffect(() => {
    // Watcher de ubicación para actualizar el mapa tan pronto como geolocation detecte cambios en las coordenadas.
    // El timeout es 0.5 veces mayor al maximumAge para darle un margen de tiempo de error al refrescar la ubicación.
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        updateLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
          timestamp: position.timestamp,
        });
      },
      (error) => {
        updateLocation(null);
        onError(`${error.message} [code: ${error.code}]`);
      },
      { enableHighAccuracy: true, maximumAge, timeout: maximumAge * 1.5 }
    );

    return () => {
      // Importante limpiar el watcher al desmontar el componente
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  let locationText = null;
  if (showLocation && location != null) {
    locationText = (
      <small>
        {Number(location.latitude).toFixed(6)},
        {Number(location.longitude).toFixed(6)}{" "}
        {Number(location.accuracy).toFixed(1)}{" "}
        {format(new Date(location.timestamp), "HH:mm:ss")}
      </small>
    );
  }

  return (
    <Tag
      size={12}
      variant="outline"
      variantColor="gray"
      p={1}
      px={3}
      rounded="full"
      fontWeight="bold"
    >
      <FontAwesomeIconBox icon={faLocationArrow} mr={1} />
      <TagLabel ml={1}>{locationText || <Spinner />}</TagLabel>
    </Tag>
  );
}
