import React from 'react';
import { Flex, Heading, Box } from '@chakra-ui/core';
import { VisitaCard } from './VisitaCard';
import { FontAwesomeIconBox } from './FontAwesomeIconBox';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

/**
 * Lista de visitas programadas
 * @param {array<object>} visitas
 * @param {boolean} isLoading
 * @returns {*}
 * @constructor
 */
function Schedule({ visitas = [], isLoading = false }) {
  const cards = visitas.map((visita) => {
    let status = 'Pendiente';
    if (visita.checkOut) {
      status = 'Completado';
    } else if (visita.checkIn) {
      status = 'En progreso';
    }

    return (
      <VisitaCard
        isLoaded={!isLoading}
        width="100%"
        key={visita.id}
        idVisita={visita.id}
        locationName={visita.pdv.nombre}
        address={visita.pdv.direccion}
        scheduleType={visita.tipoVisita}
        status={status}
        mt={2}
        ml={0}
        mr={0}
        locationCode={visita.pdv.code}
      />
    );
  });

  return (
    <Box borderRight={[0, 0, '1px solid #ddd']} width={['100%', '100%', '45%', '35%', '25%']}>
      <Box textAlign="center" mb={2}>
        <Heading d="inline" textAlign="center">
          <FontAwesomeIconBox icon={faCalendarCheck} d="inline" mr={2} />
          Puntos del dia
        </Heading>
      </Box>
      <Flex
        padding={[0, 0, 2]}
        direction="column"
        justify="start"
        width="100%"
        overflowY="auto"
        height={['auto', 'auto', 'calc(100vh - 140px)']}
      >
        {cards}
      </Flex>
    </Box>
  );
}

export { Schedule };
