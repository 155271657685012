import React, {useEffect, useState} from "react";
import {Box, Button, Heading, Link, Text} from "@chakra-ui/core";
import {legacyApiClient} from "../../service/apiClient";
import {useParams} from 'react-router-dom';
import {LEGACY_API_ENDPOINT} from "../../config";

export default function Success() {
  const {idVersion, idEncuesta, idConjunto} = useParams();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    legacyApiClient('/encuestas')
      // // /:id_encuesta/versiones/:id_version/conjunto_respuestas/:id_conjunto/reportes
      .get(`/${idEncuesta}/versiones/${idVersion}/conjunto_respuestas/${idConjunto}/reportes`)
      .then(res => setReports(res.data));
  }, [])

  const cards = reports.map(r => {
    return <Box key={r.code} padding={3} shadow="md" borderWidth="1px" m="8px" position="relative">
      <Heading as="h3" size="lg" isTruncated>{r.nombre}</Heading>
      <Box>
        <Text mt={2} ml={2} d="inline-block">
          {r.descripcion}
        </Text>
      </Box>
      <Box textAlign="left">
        <Button variantColor="teal" variant="solid" as={Link}
                href={`${LEGACY_API_ENDPOINT}/encuestas/${idEncuesta}/versiones/${idVersion}/conjunto_respuestas/${idConjunto}/reportes/${r.code}`}
                isExternal>
          Descargar
        </Button>
      </Box>
    </Box>
  });

  return (
    <Box p={5}>
      <Heading textAlign="center">Formulario completado</Heading>
      <br/>
      {cards}
    </Box>
  );
}
