import {fromJS} from "immutable";
import * as actions from '../actions/prospeccionesActions'


const initialState = fromJS({
  error: null,
  addding: false,
  saved: false,
  departamentos: null,
  data: null
});


export default function prospeccionesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_DEPARTAMENTOS_REQUEST:
      return state.mergeDeep({
        error: null
      });
    case actions.GET_DEPARTAMENTOS_SUCCESS:
      return state.mergeDeep({
        error: null,
        departamentos: action.departamentos
      });

    case actions.GET_DEPARTAMENTOS_FAILURE:
      return state.mergeDeep({
        error: action.err
      });

    case actions.GET_MUNICIPIOS_REQUEST:
      return state.mergeDeep({
        error: null,
        departamentos: {
          [action.idDepartamento]: {
            municipios: {}
          }
        }
      });

    case actions.GET_MUNICIPIOS_SUCCESS:
      return state.mergeDeep({
        error: null,
        departamentos: {
          [action.idDepartamento]: {
            municipios: action.municipios
          }
        }
      });

    case actions.GET_MUNICIPIOS_FAILURE:
      return state.mergeDeep({
        error: action.err
      });

    case actions.ADD_PROSPECCION_REQUEST:
      return state.mergeDeep({
        error: null,
        addding: true
      });

    case actions.ADD_PROSPECCION_FAILURE:
      return state.mergeDeep({
        saved: false,
        error: action.err,
        addding: false
      });

    case actions.ADD_PROSPECCION_SUCCESS:
      return state.mergeDeep({
        saved: true,
        error: null,
        addding: false
      });

    default:
      return state;
  }
}
