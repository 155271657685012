import React, { useRef } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

/**
 * Dialog de confirmación
 * @param {string} title Titulo del dialog de confirmación
 * @param {string} message Mensaje a mostrar en el popup
 * @param {function} isOpen hook isOpen
 * @param {function} onConfirm callback a ejecutar al confirmar acción
 * @param {function} onClose hook onClose
 * @param {function} onOpen hook onOpen
 * @returns {*}
 * @constructor
 */
export function ConfirmDialog({ title, message, isOpen, onConfirm, onClose, onOpen }) {
  const initialRef = useRef();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs" initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button variantColor="gray" variant="ghost" mr={3} onClick={onClose} ref={initialRef}>
            Cancelar
          </Button>
          <Button variantColor="orange" variant="ghost" onClick={onConfirm}>
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
