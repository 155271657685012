export const API_ENDPOINT = 'https://btl.insight.com.gt/api/v1';
export const LEGACY_API_ENDPOINT = 'https://www.centracom.com.gt/api-btl/v2';
export const GPS_TOLERANCE = 50;
export const GPS_MAX_AGE = 30000;
export const GOOGLE_MAPS_KEY = "AIzaSyAhXGyH38og2XXC_BjJBysp-gvBiUPwZlg";
export const VIATICOS_OTROS_ID = 63;
export const VIATICOS_COMBUSTIBLE_ID = 64;
export const VIATICOS_ENCUESTA_IDS = [VIATICOS_COMBUSTIBLE_ID, VIATICOS_OTROS_ID];

export const ONE_DRIVE_SCOPES = ['Files.ReadWrite.AppFolder', 'Files.ReadWrite'];
export const ONE_DRIVE_USERNAME = 'noreply@centracom.com.gt';
export const ONE_DRIVE_PASSWORD = '$7ir1011Desa%2013';
export const AZURE_APP_ID = '545ce5dc-ebd9-4d09-9423-c9fef4d4fa0d';
export const AZURE_TENANT_ID = 'b06e9e76-9bdf-4000-bf69-77948aef570d';
