import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {NotInRoute} from './components/RouteBased/NotInRoute';
import {Navigation} from './components/Nav/Navigation';
import {PrivateRoute} from './components/RouteBased/PrivateRoute';
import {ListaVisitas} from './components/pages/ListaVisitas';
import {Visita} from './components/pages/Visita';
import {Login} from './components/pages/Login';
import {ChangePassword} from './components/pages/ChangePassword';
import ListaEncuestas from "./components/pages/ListaEncuestas";
import Encuesta from "./components/pages/Encuesta";
import Success from "./components/pages/Success";
import Viaticos from "./components/pages/Viaticos";
import ViaticosEncuesta from "./components/pages/ViaticosEncuesta";
import {Prospecciones} from "./components/pages/Prospecciones";
import DocumentBrowser from "./components/pages/DocumentBrowser";

/**
 * Rutas disponibles del sitio
 * @returns {*}
 * @constructor
 */
export function Routes() {
  return (
    <Router>
      <NotInRoute routes={['/login', '/viaticos/\\d{6}', '/prospeccion', '/document-browser']} component={Navigation}/>
      <Switch>
        <PrivateRoute exact path="/visita/:idVisita/encuestas/:idEncuesta/versiones/:idVersion/conjunto/:idConjunto/success"
                      component={Success}/>
        <PrivateRoute exact path="/visita/:idVisita/encuestas/:idEncuesta" component={Encuesta}/>
        <PrivateRoute exact path="/visita/:idVisita/encuestas" component={ListaEncuestas}/>
        <PrivateRoute exact path="/visita/:idVisita" component={Visita}/>
        <PrivateRoute exact path="/password" component={ChangePassword}/>
        <Route exact path="/viaticos/:username/encuesta/:idEncuesta" render={props => <ViaticosEncuesta {...props}/>}/>
        <Route exact path="/viaticos/:username/encuesta/:idEncuesta/conjunto/:idConjunto" render={props => <ViaticosEncuesta {...props}/>}/>
        <Route exact path="/viaticos/:username" component={Viaticos}/>
        <Route exact path="/viaticos" component={Viaticos}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/prospeccion" component={Prospecciones}/>
        <Route exact path="/document-browser" component={DocumentBrowser}/>
        <PrivateRoute exact path="/" component={ListaVisitas}/>
        <Redirect to="/"/>
      </Switch>
    </Router>
  );
}
