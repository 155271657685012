import React from 'react';
import { Badge, Box, Button, Heading, Skeleton, Text } from '@chakra-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHashtag, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { InLineSkeleton } from './InLineSkeleton';

/**
 * Tarjeta de una visita programada
 * @param {number} idVisita Id de la visita
 * @param {string} locationName Nombre de la ubicación
 * @param {string} address Dirección de la ubicación
 * @param {string} scheduleType Tipo de calendarización
 * @param {string} status Estado de la visita
 * @param {string} locationCode Código de la ubicación
 * @param {boolean} isLoaded Estado de carga de los datos
 * @param {any} rest
 * @returns {*}
 * @constructor
 */
function VisitaCard({ idVisita, locationName, address, scheduleType, status, locationCode, isLoaded, ...rest }) {
  return (
    <Box padding={3} shadow="md" borderWidth="1px" m="6px" position="relative" {...rest}>
      <Badge variantColor="orange" position="absolute" right={3} top="60px">
        <InLineSkeleton isLoaded={isLoaded}>{status}</InLineSkeleton>
      </Badge>
      <Skeleton isLoaded={isLoaded} my={1}>
        <Heading isTruncated>{locationName}</Heading>
      </Skeleton>

      <Box>
        <InLineSkeleton isLoaded={isLoaded}>
          <FontAwesomeIcon icon={faHashtag} />
          <Text mt={2} ml={2} d="inline-block">
            Código: {locationCode}
          </Text>
        </InLineSkeleton>
      </Box>

      <Box>
        <InLineSkeleton isLoaded={isLoaded}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ display: 'inline' }} />
          <Text mt={2} ml={2} d="inline" overflowWrap="break-word">
            {address}
          </Text>
        </InLineSkeleton>
      </Box>

      <Box>
        <InLineSkeleton isLoaded={isLoaded}>
          <FontAwesomeIcon icon={faClock} />
          <Text mt={2} ml={2} d="inline">
            {scheduleType}
          </Text>
        </InLineSkeleton>
      </Box>

      <Box textAlign="right">
        <InLineSkeleton isLoaded={isLoaded}>
          <Button variantColor="teal" variant="solid" as={Link} to={`/visita/${idVisita}`}>
            Visitar
          </Button>
        </InLineSkeleton>
      </Box>
    </Box>
  );
}

export { VisitaCard };
