import {call, put, takeEvery} from "redux-saga/effects";
import {
  ADD_PROSPECCION_REQUEST,
  addProspeccionFailure,
  addProspeccionSuccess,
  GET_DEPARTAMENTOS_REQUEST,
  GET_MUNICIPIOS_REQUEST,
  getDepartamentosFailure,
  getDepartamentosSuccess,
  getMunicipiosFailure,
  getMunicipiosSuccess
} from "../actions/prospeccionesActions";
import * as api from '../service/prospeccionesService';

function* getDepartamentos(action) {
  try {
    const response = yield call(api.getAllDepartamentos);
    const data = response.data.reduce((result, value) => {
      result[value.id] = value;
      return result;
    }, {});
    yield put(getDepartamentosSuccess(data));
  } catch (e) {
    yield put(getDepartamentosFailure(e.message || 'Error al obtener departamentos'));
  }
}

function* getMunicipios(action) {
  try {
    const response = yield call(api.getDepartamentoMunicipios, action.idDepartamento);
    const data = response.data.reduce((result, value) => {
      result[value.id] = value;
      return result;
    }, {});
    yield put(getMunicipiosSuccess({
      idDepartamento: action.idDepartamento,
      municipios: data
    }))
  } catch (e) {
    yield put(getMunicipiosFailure({
      idDepartamento: action.idDepartamento,
      err: e.message || 'Error al obtener municipios'
    }))
  }
}

function* addProspeccion(action) {
  try {
    const response = yield call(api.addProspeccion, action.data);
    yield put(addProspeccionSuccess(response.data));
  } catch (e) {
    yield  put(addProspeccionFailure(e.toString()));
  }
}

export default function* prospeccionesSaga() {
  yield takeEvery(GET_DEPARTAMENTOS_REQUEST, getDepartamentos);
  yield takeEvery(GET_MUNICIPIOS_REQUEST, getMunicipios);
  yield takeEvery(ADD_PROSPECCION_REQUEST, addProspeccion);
}
