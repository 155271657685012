export const allDepartamentosSelector = (state) => state.getIn(['prospecciones', 'departamentos']);

export const municipiosDepartamentoSelector = (state, idDepartamento) =>
  state.getIn(['prospecciones', 'departamentos', idDepartamento, 'municipios']);


export const isAddingSelector = (state) => state.getIn(['prospecciones', 'addding']);

export const isSavedSelector = (state) => state.getIn(['prospecciones', 'saved']);


export const errorSelector = (state) => state.getIn(['prospecciones', 'error']);
