import React, {useEffect, useState} from "react";
import {getErrorSelector, getFilesAndDirsSelector, isLoadingSelector} from "../../selectors/oneDriveSelector";
import {useDispatch, useSelector} from "react-redux";
import {getAllFilesInDir} from "../../actions/oneDriveActions";
import {useLocation} from "react-router";
import Table from "../Table";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Icon,
  IconButton
} from "@chakra-ui/core";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function DocumentBrowser() {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token');

  let initialPath = query.get('path');
  if (!initialPath.endsWith('/')) {
    initialPath = `${initialPath}/`;
  }
  if (!initialPath.startsWith('/')) {
    initialPath = `/${initialPath}`;
  }

  const [pathStack, setPathStack] = useState([{name: 'Inicio', path: initialPath}])

  const items = useSelector(getFilesAndDirsSelector);
  const error = useSelector(getErrorSelector);
  const isLoading = useSelector(isLoadingSelector);


  const currentPath = pathStack[pathStack.length - 1];

  useEffect(() => {
    console.log(currentPath);
    dispatch(getAllFilesInDir(currentPath.path, token));
  }, [currentPath, token]);


  const loadPath = (path) => {
    const pathIndex = pathStack.findIndex((value) => value.path === path);

    let newStack = pathStack;

    if (pathIndex === -1) {
      newStack = [...newStack, {
        name: path,
        path: `${currentPath.path}${path}/`
      }]
    } else {
      newStack = newStack.slice(0, pathIndex + 1);
    }

    console.log({pathIndex, newStack})

    setPathStack(newStack);

  }


  const handleRowClick = (row) => {
    if (isLoading) {
      return;
    }

    if (row.downloadUrl) {
      return;
    }

    loadPath(row.relativePath);
  }

  const handleBackClick = () => {
    if (pathStack.length === 1) {
      return;
    }
    const newPathStack = [...pathStack];
    newPathStack.pop();
    setPathStack(newPathStack)
  };

  const rows = items.map(i => ({
    id: i['id'],
    name: i['name'],
    relativePath: i['name'],
    createdAt: i['createdDateTime'],
    downloadUrl: i['@microsoft.graph.downloadUrl']
  }));

  const headers = [{
    label: 'Nombre',
    key: 'name'
  }, {
    label: 'Fecha creación',
    key: 'createdAt'
  }, {
    label: '',
    key: 'downloadUrl',
    render: (value, row) => {
      if (!value) {
        return null;
      }
      return <IconButton icon="download"
                         onClick={() => window.location.href = value} aria-label="Download" variantColor="teal">
        Descargar
      </IconButton>;
    }
  }];

  if (error) {
    return (
      <Box p={5}>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon size="40px" mr={0}/>
          <AlertTitle mt={4} mb={1} fontSize="lg">
            ¡Ha ocurrido un error!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            No se ha podido cargar los datos. <br/>
            <b>Recarga la página e intenta nuevamente</b>
          </AlertDescription>
        </Alert>
      </Box>
    )
  }

  const pathBreadcrumItems = pathStack.map(p => <BreadcrumbItem key={p.name}>
    <BreadcrumbLink onClick={() => loadPath(p.path)}>{p.name}</BreadcrumbLink>
  </BreadcrumbItem>);


  return (
    <Box p={5}>
      <Button leftIcon="arrow-back"
              variant="outline"
              variantColor="teal"
              isLoading={isLoading}
              isDisabled={pathStack.length <= 1}
              onClick={handleBackClick}>Regresar</Button>
      <br/>
      <br/>
      <Breadcrumb
        separator={<Icon color="gray.300" name="chevron-right"/>}
      >
        {pathBreadcrumItems}
      </Breadcrumb>
      <br/>
      <Table columns={headers} data={rows} onRowClick={handleRowClick}/>
    </Box>
  )
}
