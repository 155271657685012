/**
 * Identificador acción para obtener todas las visitas programadas del usuario
 * @type {string}
 */
export const ALL_VISITAS_REQUEST = 'ALL_VISITAS_REQUEST';

/**
 * Identificador de acción al obtener todas las visitas programadas del usuario de forma exitosa.
 * @type {string}
 */
export const ALL_VISITAS_SUCCESS_REQUEST = 'ALL_VISITAS_SUCCESS_REQUEST';

/**
 * Identificador de acción al fallar solicitud para obtener todas las visitas programadas del usuario
 * @type {string}
 */
export const ALL_VISITAS_FAILURE_REQUEST = 'ALL_VISITAS_FAILURE_REQUEST';

/**
 * Identificador de acción para obtener datos de una visita
 * @type {string}
 */
export const VISITA_BY_ID_REQUEST = 'VISITA_BY_ID_REQUEST';

/**
 * Identificador de acción al fallar solicitud de datos de una visita
 * @type {string}
 */
export const VISITA_BY_ID_FAILURE = 'VISITA_BY_ID_FAILURE';

/**
 * Identificador de acción al obtener datos de una visita de forma exitosa
 * @type {string}
 */
export const VISITA_BY_ID_SUCCESS = 'VISITA_BY_ID_SUCCESS';

/**
 * Identificador de acción para realizar checkout
 * @type {string}
 */
export const DO_CHECK_OUT_REQUEST = 'DO_CHECK_OUT_REQUEST';

/**
 * Identificador de acción al fallar checkout
 * @type {string}
 */
export const DO_CHECK_OUT_FAILURE = 'DO_CHECK_OUT_FAILURE';

/**
 * Identificador de acción al hacer checkout exitosamente
 * @type {string}
 */
export const DO_CHECK_OUT_SUCCESS = 'DO_CHECK_OUT_SUCCESS';

/**
 * Identificador de acción para hacer checkin en un punto
 * @type {string}
 */
export const DO_CHECK_IN_REQUEST = 'DO_CHECK_IN_REQUEST';

/**
 * Identificador de acción al fallar checkin
 * @type {string}
 */
export const DO_CHECK_IN_FAILURE = 'DO_CHECK_IN_FAILURE';

/**
 * Identificador de acción al hacer checkin de forma exitosa
 * @type {string}
 */
export const DO_CHECK_IN_SUCCESS = 'DO_CHECK_IN_SUCCESS';

/**
 * Identificador de acción para iniciar horario de almuerzo
 * @type {string}
 */
export const START_LUNCH_REQUEST = 'START_LUNCH_REQUEST';

/**
 * Identificador de acción al fallar iniciar horario de almuerzo
 * @type {string}
 */
export const START_LUNCH_FAILURE = 'START_LUNCH_FAILURE';

/**
 * Identificador de acción al iniciar hora de almuerzo exitosamente
 * @type {string}
 */
export const START_LUNCH_SUCCESS = 'START_LUNCH_SUCCESS';

/**
 * Identificador de acción para finalizar horario de almuerzo
 * @type {string}
 */
export const FINISH_LUNCH_REQUEST = 'FINISH_LUNCH_REQUEST';

/**
 * Identificador de acción al fallar el inicio de horario de almuerzo
 * @type {string}
 */
export const FINISH_LUNCH_FAILURE = 'FINISH_LUNCH_FAILURE';

/**
 * Identificador de acción de fin de horario de almuerzo exitoso
 * @type {string}
 */
export const FINISH_LUNCH_SUCCESS = 'FINISH_LUNCH_SUCCESS';

/**
 * Crear acción para solicitar todas las visitas
 * @returns {{type: string}}
 */
export const getAllVisitas = () => ({
  type: ALL_VISITAS_REQUEST,
});

/**
 * Crear acción de fallo al solicitar información de todas las visitas
 * @param {string} message
 * @returns {{type: string, message: *}}
 */
export const getAllVisitasFailure = (message) => ({
  type: ALL_VISITAS_FAILURE_REQUEST,
  message,
});

/**
 * Crear acción de éxito al solicitar datos de las visitas
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const getAllVisitasSuccess = (data) => ({
  type: ALL_VISITAS_SUCCESS_REQUEST,
  data,
});

/**
 * Crear acción para obtener datos de una visita especifica
 * @param {number} idVisita
 * @returns {{idVisita: *, type: string}}
 */
export const getVisitaById = (idVisita) => ({
  type: VISITA_BY_ID_REQUEST,
  idVisita,
});

/**
 * Crear acción de fallo al obtener información de una visita
 * @param {number} idVisita
 * @param {string} message
 * @returns {{idVisita: *, type: string, message: *}}
 */
export const getVisitaByIdFailure = (idVisita, message) => ({
  type: VISITA_BY_ID_FAILURE,
  idVisita,
  message,
});

/**
 * Crear acción de éxito al obtener información de una visita
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const getVisitaByIdSuccess = (data) => ({
  type: VISITA_BY_ID_SUCCESS,
  data,
});

/**
 * Crear acción de checkout
 * @param {number} idVisita
 * @returns {{idVisita: *, type: string}}
 */
export const doCheckOut = (idVisita) => ({
  type: DO_CHECK_OUT_REQUEST,
  idVisita,
});

/**
 * Crear acción de fallo al hacer checkout
 * @param {number} idVisita
 * @param {string} message
 * @returns {{idVisita: *, type: string, message: *}}
 */
export const doCheckOutFailure = (idVisita, message) => ({
  type: DO_CHECK_OUT_FAILURE,
  idVisita,
  message,
});

/**
 * Crear acción de éxito al hacer checkout
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const doCheckOutSuccess = (data) => ({
  type: DO_CHECK_OUT_SUCCESS,
  data,
});

/**
 * Crear acción de checkin
 * @param {number} idVisita
 * @returns {{idVisita: *, type: string}}
 */
export const doCheckIn = (idVisita) => ({
  type: DO_CHECK_IN_REQUEST,
  idVisita,
});

/**
 * Crear acción de fallo al hacer checkin
 * @param {number} idVisita
 * @param {string} message
 * @returns {{idVisita: *, type: string, message: *}}
 */
export const doCheckInFailure = (idVisita, message) => ({
  type: DO_CHECK_IN_FAILURE,
  idVisita,
  message,
});

/**
 * Crear acción de éxito al hacer checkin
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const doCheckInSuccess = (data) => ({
  type: DO_CHECK_IN_SUCCESS,
  data,
});

/**
 * Crear acción de inicio de horario de almuerzo
 * @param {number} idVisita
 * @returns {{idVisita: *, type: string}}
 */
export const startLunch = (idVisita) => ({
  type: START_LUNCH_REQUEST,
  idVisita,
});

/**
 * Crear acción de fallo al iniciar horario de almuerzo
 * @param {number} idVisita
 * @param {string} message
 * @returns {{idVisita: *, type: string, message: *}}
 */
export const startLunchFailure = (idVisita, message) => ({
  type: START_LUNCH_FAILURE,
  idVisita,
  message,
});

/**
 * Crear acción de éxito al iniciar horario de almuerzo
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const startLunchSuccess = (data) => ({
  type: START_LUNCH_SUCCESS,
  data,
});

/**
 * Crear acción de fin de horario de almuerzo
 * @param {number} idVisita
 * @returns {{idVisita: *, type: string}}
 */
export const finishLunch = (idVisita) => ({
  type: FINISH_LUNCH_REQUEST,
  idVisita,
});

/**
 * Crear acción de fallo al finalizar horario de almuerzo
 * @param {number}idVisita
 * @param {string} message
 * @returns {{idVisita: *, type: string, message: *}}
 */
export const finishLunchFailure = (idVisita, message) => ({
  type: FINISH_LUNCH_FAILURE,
  idVisita,
  message,
});

/**
 * Crear acción de éxito al finalizar horario de almuerzo
 * @param {object} data
 * @returns {{data: *, type: string}}
 */
export const finishLunchSuccess = (data) => ({
  type: FINISH_LUNCH_SUCCESS,
  data,
});
