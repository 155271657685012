export const SAVE_PREGUNTA_REQUEST = 'SAVE_PREGUNTA_REQUEST';
export const SAVE_PREGUNTA_FAILURE = 'SAVE_PREGUNTA_FAILURE';
export const SAVE_PREGUNTA_SUCCESS = 'SAVE_PREGUNTA_SUCCESS';
export const CLEAR_RESPUESTAS = 'CLEAR_RESPUESTAS';

export const postRespuesta = ({idPunto, idEncuesta, idVersion, idConjunto, idPregunta, tipoDato, valor, opciones}) => ({
  type: SAVE_PREGUNTA_REQUEST,
  idEncuesta, idVersion, idConjunto, idPregunta, tipoDato, valor, opciones, idPunto
});

export const clearRespuestas = ({idPunto, idEncuesta})  => ({
  type: CLEAR_RESPUESTAS,
  idPunto, idEncuesta
});

export const postRespuestaFailure = ({idPunto, idConjunto, idEncuesta, idPregunta, error}) => ({
  type: SAVE_PREGUNTA_FAILURE,
  idConjunto, idPregunta, error, idEncuesta, idPunto
});

export const postRespuestaSuccess = ({idPunto, idConjunto, idEncuesta, idPregunta, data}) => ({
  type: SAVE_PREGUNTA_SUCCESS,
  idConjunto, idPregunta, data, idEncuesta, idPunto
});


export const CREAR_CONJUNTO_REQUEST = 'CREAR_RESPUESTA_REQUEST';
export const CREAR_CONJUNTO_FAILURE = 'CREAR_RESPUESTA_FAILURE';
export const CREAR_CONJUNTO_SUCCESS = 'CREAR_RESPUESTA_SUCCESS';


export const crearConjunto = ({idPunto, idEncuesta, versionEncuesta}) => ({
  type: CREAR_CONJUNTO_REQUEST,
  idPunto, idEncuesta, versionEncuesta
});

export const crearConjuntoFailure = ({idPunto, idEncuesta, error}) => ({
  type: CREAR_CONJUNTO_FAILURE,
  idPunto, idEncuesta, error
});

export const crearConjuntoSuccess = ({idPunto, idEncuesta, data}) => ({
  type: CREAR_CONJUNTO_SUCCESS,
  idPunto, idEncuesta, data
});

export const GET_LATEST_CONJUNTO_REQUEST = 'GET_LATEST_CONJUNTO_REQUEST';
export const GET_LATEST_CONJUNTO_SUCCESS = 'GET_LATEST_CONJUNTO_SUCCESS';
export const GET_LATEST_CONJUNTO_FAILURE = 'GET_LATEST_CONJUNTO_FAILURE';

export const getLatestConjunto = (idPunto, idEncuesta) => ({
  type: GET_LATEST_CONJUNTO_REQUEST,
  idPunto,
  idEncuesta
});

export const getLatestConjuntoFailure = (idPunto, error) => ({
  type: GET_LATEST_CONJUNTO_FAILURE,
  idPunto, error
});

export const getLatestConjuntoSuccess = (idPunto, idEncuesta, data) => ({
  type: GET_LATEST_CONJUNTO_SUCCESS,
  idPunto,
  idEncuesta,
  data
});


export const GET_RESPUESTAS_REQUEST = "GET_RESPUESTAS_REQUEST";
export const GET_RESPUESTAS_SUCCESS = "GET_RESPUESTAS_SUCCESS";
export const GET_RESPUESTAS_FAILURE = "GET_RESPUESTAS_FAILURE";

export const getRespuestas = (idPunto, idEncuesta, idConjunto, cb) => ({
  type: GET_RESPUESTAS_REQUEST,
  idEncuesta, idConjunto, idPunto, cb
});

export const getRespuestasSuccess = (idPunto, idEncuesta, idConjunto, data) => {
  return {
    type: GET_RESPUESTAS_SUCCESS,
    idEncuesta, idConjunto, idPunto, data
  };
};

export const getRespuestasFailure = (idPunto, idEncuesta, idConjunto, error) => ({
  type: GET_RESPUESTAS_FAILURE,
  idEncuesta, idConjunto, idPunto, error
});


export const CREAR_CONJUNTO_VIATICOS_REQUEST = 'CREAR_CONJUNTO_VIATICOS_REQUEST';
export const CREAR_CONJUNTO_VIATICOS_FAILURE = 'CREAR_CONJUNTO_VIATICOS_FAILURE';
export const CREAR_CONJUNTO_VIATICOS_SUCCESS = 'CREAR_CONJUNTO_VIATICOS_SUCCESS';

export const crearConjuntoViaticos = ({username, idEncuesta, versionEncuesta, newIdentifier}) => ({
  type: CREAR_CONJUNTO_VIATICOS_REQUEST,
  username, idEncuesta, versionEncuesta, newIdentifier
});

export const crearConjuntoFailureViaticos = ({username, idEncuesta, error}) => ({
  type: CREAR_CONJUNTO_VIATICOS_FAILURE,
  username, idEncuesta, error
});

export const crearConjuntoSuccessViaticos = ({username, idEncuesta, data, newIdentifier}) => ({
  type: CREAR_CONJUNTO_VIATICOS_SUCCESS,
  username, idEncuesta, data, newIdentifier
});
