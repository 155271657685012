import React, {useEffect} from "react";
import {Box, Flex} from "@chakra-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getAllStdEncuestasSelector} from "../../selectors/EncuestasSelector";
import {getAllEncuestas} from "../../actions/encuestasActions";
import EncuestaCard from "../EncuestaCard";
import {useParams} from "react-router-dom";

export default function ListaEncuestas() {
  const dispatch = useDispatch();
  const encuestas = useSelector(state => getAllStdEncuestasSelector(state));
  const {idVisita} = useParams();

  useEffect(() => {
    dispatch(getAllEncuestas());
  }, []);

  const cards = (encuestas || []).map(encuesta => (
    <EncuestaCard key={encuesta['idEncuesta']} idVisita={idVisita} idEncuesta={encuesta['idEncuesta']}
                  title={encuesta['nombre']} description={encuesta['descripcion']}/>
  ));

  return (
    <Flex direction="row" flexWrap="wrap" height="100%">
      <Box width={["100%", "100%", "45%", "35%", "25%"]}>
        {cards}
      </Box>
    </Flex>
  );
}
