export const GET_VIATICOS_MES_REQUEST = 'GET_VIATICOS_MES_REQUEST';
export const GET_VIATICOS_MES_SUCCESS = 'GET_VIATICOS_MES_SUCCESS';
export const GET_VIATICOS_MES_FAILURE = 'GET_VIATICOS_MES_FAILURE';

export const getViaticosMes = ({username, year, month}) => ({
  type: GET_VIATICOS_MES_REQUEST,
  username, year, month
});

export const getViaticosMesSuccess = ({username, year, month, data}) => ({
  type: GET_VIATICOS_MES_SUCCESS,
  username, year, month, data
});

export const getViaticosMesFailure = ({username, year, month, error}) => ({
  type: GET_VIATICOS_MES_FAILURE,
  username, year, month, error
});
