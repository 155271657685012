import {call, put, takeLatest} from "redux-saga/effects";
import {
  GET_ALL_FILES_IN_DIR_REQUEST,
  getAllFilesInDirFailure,
  getAllFilesInDirSuccess
} from "../actions/oneDriveActions";
import {list} from '../service/oneDrive'

function* getAllFilesInDir(action) {
  try {
    const response = yield call(list, action);
    yield put(getAllFilesInDirSuccess(response.data.value));
  } catch (e) {
    yield put(getAllFilesInDirFailure(e.message || 'Error al obtener archivos'));
  }
}


export default function* oneDriveSaga() {
  yield takeLatest(GET_ALL_FILES_IN_DIR_REQUEST, getAllFilesInDir);
}
