import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Flex, Heading, useToast} from "@chakra-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getEncuestaByIdSelector} from "../../selectors/EncuestasSelector";
import {useHistory, useParams} from 'react-router-dom';
import {getAllEncuestas, getEncuestaSecciones, getSeccionPreguntas} from "../../actions/encuestasActions";
import SectionContainer from "../Encuesta/SectionContainer";
import {getConjuntoViaticosSelector, getRespuestasSelector} from "../../selectors/respuestaSelector";
import {crearConjuntoSuccessViaticos, crearConjuntoViaticos, getRespuestas} from "../../actions/respuestasActions";
import {v4 as uuidv4} from 'uuid';


export default function ViaticosEncuesta() {
  const dispatch = useDispatch();
  const {username, idEncuesta, idConjunto} = useParams();
  const [newIdentifier, setNewIdentifier] = useState(null);
  const encuesta = useSelector(state => getEncuestaByIdSelector(state, idEncuesta));
  const conjunto = useSelector(state => getConjuntoViaticosSelector(state, username, idEncuesta, idConjunto, newIdentifier));

  const [loadingPreguntas, setLoadingPreguntas] = useState(false);
  const [preguntaDone, setPreguntasDone] = useState(false);
  const [loadingRespuestas, setLoadingRespuestas] = useState(false);
  const [respuestasDone, setRespuestasDone] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const respuestas = useSelector(state => getRespuestasSelector(
    state,
    conjunto ? conjunto.id_usuario : 0,
    encuesta ? encuesta.idEncuesta : 0,
    conjunto ? conjunto.idConjunto : null,
    true)
  );

  useEffect(() => {
    if (encuesta) {
      return;
    }
    dispatch(getAllEncuestas());
  }, [encuesta]);

  useEffect(() => {
    if (conjunto || !encuesta) {
      return;
    }

    if (!newIdentifier) {
      setNewIdentifier(uuidv4());
      return;
    }

    if (idConjunto) {
      dispatch(crearConjuntoSuccessViaticos({
        username, idEncuesta, data: {
          idConjunto,
          isLoading: false,
          error: null,
          conjunto_id: idConjunto,
          id_usuario: username,
          id_encuesta: idEncuesta
        }
      }));
      return;
    }

    dispatch(crearConjuntoViaticos({username, idEncuesta, versionEncuesta: encuesta.currentVersion, newIdentifier}))
  }, [conjunto, encuesta, newIdentifier])

  useEffect(() => {
    if (!encuesta || encuesta.secciones) {
      return;
    }

    dispatch(getEncuestaSecciones({idEncuesta, idVersion: encuesta.currentVersion}));
  }, [encuesta]);

  useEffect(() => {
    if (!encuesta || !encuesta.secciones || preguntaDone) {
      return;
    }

    const allLoaded = encuesta.secciones.filter(x => {
      return x.preguntas;
    });

    if (allLoaded.length === encuesta.secciones.length) {
      setPreguntasDone(true);
      return;
    }

    if (loadingPreguntas) {
      return;
    }

    const timer = setTimeout(() => {
      encuesta.secciones.forEach(s => {
        dispatch(getSeccionPreguntas({
          idEncuesta,
          idVersion: encuesta.currentVersion,
          idSeccion: s.idSeccion,
        }))
      });
    }, 1000);

    setLoadingPreguntas(true);
    return () => clearTimeout(timer);
  }, [encuesta]);


  useEffect(() => {
    if (!conjunto || !encuesta || !encuesta.secciones) {
      return;
    }

    if (!preguntaDone || loadingRespuestas || respuestasDone) {
      return;
    }

    const timer = setTimeout(() => {
      dispatch(getRespuestas(username, idEncuesta, conjunto.idConjunto, () => {
        setLoadingRespuestas(false);
        setRespuestasDone(true);
      }));
    }, 1000);

    setLoadingRespuestas(true);
    return () => clearTimeout(timer);
  }, [conjunto, encuesta, preguntaDone]);

  if (!conjunto || !encuesta || !encuesta.secciones || !respuestasDone) {
    return <Box textAlign="center" m={10}>
      <CircularProgress isIndeterminate color="green"/>
    </Box>;
  }

  const handleFinish = () => {
    const mainPreguntas = [].concat.apply([], encuesta.secciones.map(s => s.preguntas)).filter(x => !x.idPadre);
    const answers = mainPreguntas.filter(p => respuestas[p.idPregunta]) || [];

    if (mainPreguntas.length === answers.length) {
      history.push(`/viaticos/${username}`);
    } else {
      toast({
        title: "Campos requeridos",
        description: "Por favor completa todos los campos",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  };

  return (
    <Flex direction="row" flexWrap="wrap" height="100%">
      <Box width={["100%", "100%", "45%", "35%", "25%"]}>
        <Heading as="h3" size="lg" textAlign="center" mt={5} mb={5}>{encuesta.nombre}</Heading>
        <SectionContainer secciones={encuesta.secciones} conjunto={conjunto} encuesta={encuesta}
                          respuestas={respuestas}/>
        <br/>
        <Box p={5}>
          <Button variantColor="green" w="100%" onClick={handleFinish}>Finalizar</Button>
        </Box>
      </Box>
    </Flex>
  );
}
