import React, {useEffect, useState} from "react";
import {Input} from "@chakra-ui/core";

export default function PreguntaDate({value, onChange, init}) {
  const [currentDate, setCurrentDate] = useState(value);
  useEffect(() => {
    init(value, "date");
  }, []);
  return (
    <Input type="date" size="md" variant="outline" onChange={e => {
      setCurrentDate(e.target.value);
      onChange(e.target.value);
    }} value={currentDate}/>
  );
}
