import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../selectors/AuthSelectors';

/**
 * Previene el renderizado del componente cuando el usuario no esta autenticado
 * @param Component Componente a proteger
 * @param {any} rest
 * @returns {*}
 * @constructor
 */
export function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector((state) => isAuthenticatedSelector(state));

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
