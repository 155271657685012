import React from "react";
import {AccordionHeader, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Flex} from "@chakra-ui/core";
import PreguntasContainer from "./Pregunta/PreguntasContainer";

export default function SectionAccordion({title, description, preguntas, conjunto, encuesta, progress, respuestas}) {

  return (
    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left" fontWeight="bold" fontSize="1.1em">
          <Flex align="center">
            <span style={{flexGrow: 1}}>{title}</span>
            <Badge variant="solid" variantColor={progress === '100' ? 'green' : 'red'}>{progress}%</Badge>
          </Flex>
        </Box>
        <AccordionIcon/>
      </AccordionHeader>
      <AccordionPanel pl={2} pr={2}>
        <Box mb={2}>
          {description}
        </Box>
        <PreguntasContainer preguntas={preguntas} encuesta={encuesta} conjunto={conjunto} respuestas={respuestas}/>
      </AccordionPanel>
    </AccordionItem>
  );
}
