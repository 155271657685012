import React from 'react';
import {isVisitaLoading, visitaCheckInSelector, visitaCheckOutSelector} from '../../selectors/VisitasSelector';
import {Box, Button, Skeleton} from '@chakra-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListOl} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';
import {Link} from "react-router-dom";

export default function EncuestasBtn({idVisita, ...rest}) {
  const checkIn = useSelector((state) => visitaCheckInSelector(state, idVisita));
  const checkOut = useSelector((state) => visitaCheckOutSelector(state, idVisita));
  const isLoading = useSelector((state) => isVisitaLoading(state, idVisita));

  if (!checkIn || checkOut) {
    return null;
  }

  return (
    <Skeleton isLoaded={!isLoading} my={1}>
      <Button size="lg" variant="outline" {...rest} as={Link} to={`/visita/${idVisita}/encuestas`}>
        <FontAwesomeIcon icon={faListOl}/>
        <Box ml={1}>Validación de visita</Box>
      </Button>
    </Skeleton>
  );
}
