import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
  Link as WebLink,
  useDisclosure
} from "@chakra-ui/core";
import {faFileInvoice, faGasPump} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIconBox,} from "../FontAwesomeIconBox";
import ViaticosList from "../ViaticosList";
import {LEGACY_API_ENDPOINT, VIATICOS_COMBUSTIBLE_ID, VIATICOS_OTROS_ID} from "../../config";

export default function Viaticos() {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {username} = useParams();

  const currentDate = new Date();

  return (
    <>
      <Box width="100%">
        <Heading as="h3" size="lg" textAlign="center" mt={5} mb={5} width="100%">
          Viáticos
          <IconButton aria-label="Descargar reporte" icon="download" variant="outline"
                      style={{position: 'absolute', right: 10}}
                      as={WebLink}
                      href={`${LEGACY_API_ENDPOINT}/viaticos/usuarios/${username}/year/${currentDate.getFullYear()}/month/${currentDate.getMonth()+1}/report`}/>
        </Heading>
        <Flex align="center" direction="column" height="calc(100vh - 120px)">
          <Flex width="100%" flexGrow={1}>
            <ViaticosList username={username}/>
          </Flex>
          <Flex>
            <Button variantColor="teal" flexGrow={1} onClick={onOpen}>
              Agregar factura
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xs">
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Tipo de factura</ModalHeader>
          <ModalBody textAlign="center">
            <Button variantColor="blue" variant="outline" width="100%"
                    mb={5} as={Link} to={`/viaticos/${username}/encuesta/${VIATICOS_COMBUSTIBLE_ID}`}>
              <FontAwesomeIconBox mr={2} icon={faGasPump}/>
              Combustible
            </Button>
            <Button variantColor="red" variant="outline" width="100%" as={Link}
                    to={`/viaticos/${username}/encuesta/${VIATICOS_OTROS_ID}`}>
              <FontAwesomeIconBox mr={2} icon={faFileInvoice}/>
              Otros
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
