import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Skeleton, useDisclosure } from '@chakra-ui/core';
import { ConfirmDialog } from '../ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  isVisitaLoading,
  visitaCheckInSelector,
  visitaCheckOutSelector,
  visitaFinishLunchSelector,
  visitaStartLunchSelector,
} from '../../selectors/VisitasSelector';
import { finishLunch, startLunch } from '../../actions/visitasActions';

/**
 * Botón de inicio y fin de horario de almuerzo
 * @param {number} idVisita
 * @param {any} rest
 * @returns {null|*}
 * @constructor
 */
export function LunchBtn({ idVisita, ...rest }) {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lunchStartedAt = useSelector((state) => visitaStartLunchSelector(state, idVisita));
  const lunchFinishedAt = useSelector((state) => visitaFinishLunchSelector(state, idVisita));
  const checkIn = useSelector((state) => visitaCheckInSelector(state, idVisita));
  const checkOut = useSelector((state) => visitaCheckOutSelector(state, idVisita));
  const isLoading = useSelector((state) => isVisitaLoading(state, idVisita));

  const lunchInProgress = lunchStartedAt !== null;

  /**
   * Iniciar acción de inicio o fin de almuerzo al confirmar en popup de confirmación
   */
  const onDialogConfirm = () => {
    if (lunchInProgress) {
      dispatch(finishLunch(idVisita));
    } else {
      dispatch(startLunch(idVisita));
    }
    onClose();
  };

  let btnText = null;
  if (lunchFinishedAt) {
    btnText = 'Almuerzo finalizado';
  } else if (lunchStartedAt) {
    btnText = 'Finalizar almuerzo';
  } else {
    btnText = 'Iniciar almuerzo';
  }

  if (!checkIn || (checkOut && !lunchStartedAt)) {
    return null;
  }

  return (
    <Skeleton isLoaded={!isLoading} my={1}>
      <Button
        size="lg"
        variant="outline"
        variantColor={lunchInProgress ? 'orange' : 'teal'}
        isDisabled={lunchStartedAt && lunchFinishedAt}
        {...rest}
        onClick={onOpen}
      >
        <FontAwesomeIcon icon={lunchInProgress ? faUtensils : faUtensils} />
        <Box ml={1}>{btnText}</Box>
        <ConfirmDialog
          onConfirm={onDialogConfirm}
          isOpen={isOpen}
          onClose={onClose}
          title={lunchInProgress ? 'Finalizar almuerzo' : 'Iniciar almuerzo'}
          message={
            lunchInProgress ? '¿Quieres finalizar tu horario de almuerzo?' : '¿Quieres iniciar tu horario de almuerzo?'
          }
        />
      </Button>
    </Skeleton>
  );
}
