import React from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Evita renderizar componentes en las rutas especificadas
 * @param {array<string>} routes Rutas en las cuales no se quiere renderizar el componente
 * @param {any} Component Componente a renderizar condicionalmente
 * @returns {*}
 * @constructor
 */
export function NotInRoute({ routes = [], component: Component }) {
  const location = useLocation();

  const matches = routes.filter(r => {
    const regex = new RegExp(r);
    return regex.test(location.pathname);
  });

  if (matches.length > 0) {
    return null;
  }

  return <Component />;
}
