import React from 'react';
import { Skeleton } from '@chakra-ui/core';

/**
 * Inline skeleton wrapper
 * @param children componente hijo
 * @param {boolean} isLoaded estado de carga del componente
 * @param rest
 * @returns {*}
 * @constructor
 */
export function InLineSkeleton({ children, isLoaded, ...rest }) {
  return (
    <Skeleton isLoaded={isLoaded} my={1} d="inline-block">
      {children}
    </Skeleton>
  );
}
