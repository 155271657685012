export const GET_DEPARTAMENTOS_REQUEST = 'GET_DEPARTAMENTOS_REQUEST';
export const GET_DEPARTAMENTOS_SUCCESS = 'GET_DEPARTAMENTOS_SUCCESS';
export const GET_DEPARTAMENTOS_FAILURE = 'GET_DEPARTAMENTOS_FAILURE';

export const getDepartamentos = () => ({
  type: GET_DEPARTAMENTOS_REQUEST
});

export const getDepartamentosSuccess = (departamentos) => ({
  type: GET_DEPARTAMENTOS_SUCCESS,
  departamentos
});

export const getDepartamentosFailure = (err) => ({
  type: GET_DEPARTAMENTOS_FAILURE,
  err
});

export const GET_MUNICIPIOS_REQUEST = 'GET_MUNICIPIOS_REQUEST';
export const GET_MUNICIPIOS_SUCCESS = 'GET_MUNICIPIOS_SUCCESS';
export const GET_MUNICIPIOS_FAILURE = 'GET_MUNICIPIOS_FAILURE';

export const getMunicipios = (idDepartamento) => ({
  type: GET_MUNICIPIOS_REQUEST,
  idDepartamento
});

export const getMunicipiosSuccess = ({idDepartamento, municipios}) => ({
  type: GET_MUNICIPIOS_SUCCESS,
  idDepartamento,
  municipios
});

export const getMunicipiosFailure = ({idDepartamento, err}) => ({
  type: GET_MUNICIPIOS_FAILURE,
  idDepartamento,
  err
});

export const ADD_PROSPECCION_REQUEST = 'ADD_PROSPECCION_REQUEST';
export const ADD_PROSPECCION_SUCCESS = 'ADD_PROSPECCION_SUCCESS';
export const ADD_PROSPECCION_FAILURE = 'ADD_PROSPECCION_FAILURE';

export const addProspeccion = ({username, idMunicipio, name, latitude, longitude, address, typeCode, otrosBancos}) => ({
  type: ADD_PROSPECCION_REQUEST,
  data: {username, idMunicipio, name, latitude, longitude, address, typeCode, otrosBancos}
});


export const addProspeccionSuccess = (data) => ({
  type: ADD_PROSPECCION_SUCCESS,
  data
});

export const addProspeccionFailure = (err) => ({
  type: ADD_PROSPECCION_FAILURE,
  err
});
