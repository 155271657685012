import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/core";
import PreguntasContainer from "./PreguntasContainer";

export default function ModalDetalles({shouldShow, encuesta, conjunto, subPreguntas, respuestas}) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <Button variantColor="red" onClick={onOpen}>Detalles</Button>
      <Modal preserveScrollBarGap isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton/>
          <ModalBody p={2}>
            <PreguntasContainer preguntas={subPreguntas} encuesta={encuesta} conjunto={conjunto} isNested={true} respuestas={respuestas}/>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button variant="ghost">Aceptar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
